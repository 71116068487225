
export const HttpMethodEnum = {
  GET: 1,
  POST: 2,
  PUT: 3,
  DELETE: 4
};

export const HttpResponseCodeEnum = {
  OK: 200,
  BADREQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  SERVERERROR: 500
};

export type HttpMethod = typeof HttpMethodEnum.GET | typeof HttpMethodEnum.POST | typeof HttpMethodEnum.PUT | typeof HttpMethodEnum.DELETE;
