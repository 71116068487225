import { Injectable } from '@angular/core';
import { LoggerService } from "./logger-service";
import { ProxyService } from "./proxy-service";
import { Constants } from "../constants";
import { ContextService } from "./context-service";
 
@Injectable() 
export class LookupService {
    constructor(private proxyService: ProxyService,
        private contextService: ContextService,
        private constants: Constants)
    {
    }       
}