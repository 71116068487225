import { Injectable } from '@angular/core';
import { ContextInfo } from '../../@shared/models/context-info';
import { Message } from 'primeng/primeng';
import * as _ from 'lodash';
import { SessionStorageService } from './session-storage-service';
import { LocalStorageService } from './local-storage-service';
import { LoggerService } from './logger-service';
import { UserStore } from '../../@shared/models/user-store';
import { ILoginSetting } from '../models/login';
import * as moment from 'moment-timezone';

@Injectable()
export class ContextService {
  _contextInfo: ContextInfo = new ContextInfo();
  _loggedIn = false;
  private storeTimeZonesCache = new Map<number, string>();
  private timezonesList: string[];
  private readonly CLIENTKEY = 'skcc-clientId';
  private readonly OAUTHLOGINACTIVE = 'skcc-oauth-login';
  private readonly OAUTHLOGINSETTINGS = 'skcc-login-settings';
  private readonly SELECTEDSTORES = 'skcc-selected-stores';

  public messages: Message[];

  constructor(
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private loggerService: LoggerService,
  ) {
    // HUD messages
    this.messages = new Array<Message>();
    this.timezonesList = moment.tz.names();
  }

  clear() {
    this._contextInfo = new ContextInfo();
    this._loggedIn = false;
    this.sessionStorageService.clearUserContext();
  }

  userContext(): ContextInfo {
    return this._contextInfo;
  }

  isUserTokenExpired() {
    const expiryDate = this.userContext().tokenExpires;
    const isExpired = new Date() > expiryDate;
    this.loggerService.logData(isExpired);
    return isExpired;
  }

  setUserFromStorage(storedUser: ContextInfo) {
    if (storedUser) {
      this._contextInfo.mapFromStorage(storedUser);
      console.log(storedUser);
      this._loggedIn = true;
      this.setStorageContext(this._contextInfo);
    } else {
      this.clear();
    }
  }

  setUserStores(stores: any[]) {
    const userStores: UserStore[] = _.map(stores, (s) => {
      return {
        storeId: s.storeId,
        name: s.storeName,
        state: s.store.stateProvince,
        cityTown: s.store.cityTown,
        storeNumber: s.store.storeSourceKey,
        storePhoneNumber: s.storePhoneNumber,
        consolidatedApprovalIsActive: s.consolidatedApprovalIsActive,
        timeZoneName: s.store.timeZoneName,
        minTimeIntervalInMinutesForPromiseTimeUpdate: s.minTimeIntervalInMinutesForPromiseTimeUpdate,
        signalRMessagingEnabled: s.signalRMessagingEnabled
      };
    });
    this._contextInfo.setUserStores(userStores);
    this.sessionStorageService.setUserContext(this.userContext());
  }

  setUserContextFromLogin(userObj: ContextInfo) {
    this._contextInfo.mapFromLoginResponse(userObj);
    this.setStorageContext(this.userContext());
    this.sessionStorageService.setUserContext(this.userContext());
    this.loggerService.logData({ message: 'user logged in', user: this.userContext() });
    this._loggedIn = true;
  }

  getClientId(): number {
    const clientId = this.sessionStorageService.get(this.CLIENTKEY, null, false);
    return !clientId ? this.localStorageService.get(this.CLIENTKEY, null, false) : clientId;
  }

  setClientId(clientId: number) {
    this.sessionStorageService.set(this.CLIENTKEY, clientId, false);
    this.localStorageService.set(this.CLIENTKEY, clientId, false);
  }

  removeClientId() {
    this.sessionStorageService.remove(this.CLIENTKEY, false);
    this.localStorageService.remove(this.CLIENTKEY, false);
  }

  oauthLoginIsActive(): boolean {
    const isActive = this.sessionStorageService.get(this.OAUTHLOGINACTIVE, false, false);
    return !isActive ? this.localStorageService.get(this.OAUTHLOGINACTIVE, false, false) : isActive;
  }

  setOAuthLoginIsActive(isActive: boolean) {
    this.sessionStorageService.set(this.OAUTHLOGINACTIVE, isActive, false);
    this.localStorageService.set(this.OAUTHLOGINACTIVE, isActive, false);
  }

  removeOAuthLogin() {
    this.sessionStorageService.remove(this.OAUTHLOGINACTIVE, false);
    this.localStorageService.remove(this.OAUTHLOGINACTIVE, false);
  }

  getLoginLoginSettings(): ILoginSetting {
    const settings = this.sessionStorageService.get(this.OAUTHLOGINSETTINGS, false, null);
    return !settings ? this.localStorageService.get(this.OAUTHLOGINSETTINGS, false, null) : settings;
  }

  setLoginLoginSettings(settings: ILoginSetting) {
    this.sessionStorageService.set(this.OAUTHLOGINSETTINGS, settings, false);
    this.localStorageService.set(this.OAUTHLOGINSETTINGS, settings, false);
  }

  removeLoginSettings() {
    this.sessionStorageService.remove(this.OAUTHLOGINSETTINGS, false);
    this.localStorageService.remove(this.OAUTHLOGINSETTINGS, false);
  }

  public get loggedIn(): boolean {
    return this._loggedIn;
  }

  private setStorageContext(userObj: ContextInfo) {
    this.sessionStorageService.setStorageContext(userObj);
    this.localStorageService.setStorageContext(userObj);
  }

  public isStoreManager() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('storeManager') >= 0);
    }
    return false;
  }

  public isClientAdmin() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('clientAdmin') >= 0 ||
        user.claims.indexOf('systemAdmin') >= 0);
    }
    return false;
  }

  public isSysAdmin() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('systemAdmin') >= 0);
    }
    return false;
  }

  public isSupportAdmin() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('supportAdmin') >= 0);
    }
    return false;
  }

  public isAManager() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('clientAdmin') >= 0 || user.claims.indexOf('systemAdmin') >= 0
        || user.claims.indexOf('storeManager') >= 0 || user.claims.indexOf('leadShopper') >= 0);
    }
    return false;
  }

  public isAPureManager() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('clientAdmin') >= 0 || user.claims.indexOf('systemAdmin') >= 0
        || user.claims.indexOf('storeManager') >= 0);
    }
    return false;
  }

  public isALeadShopper() {
    const user = this.userContext();
    if (user.claims) {
      return user.claims.indexOf('leadShopper') >= 0;
    }
    return false;
  }

  public isCommandCenterOnly() {
    const user = this.userContext();
    if (user.claims) {
      return (user.claims.indexOf('commandCenterOnly') >= 0);
    }
    return false;
  }

  public isCurbsideOnly() {
    const user = this.userContext();
    return user.curbsideOnly == true;
  }

  public getStoreTimeZone(storeId: any): string {
    if (this.storeTimeZonesCache.has(storeId)) {
      return this.storeTimeZonesCache.get(storeId);
    }

    const store = this._contextInfo.stores.find(x => x.storeId == storeId);
    if (store && store.timeZoneName) {
      const timeZone = this.isValidTimeZoneName(store.timeZoneName) ? store.timeZoneName : 'US/Eastern';
      this.storeTimeZonesCache.set(storeId, timeZone);
      return timeZone;
    }

    return 'US/Eastern';
  }

  private isValidTimeZoneName(timezone: string): boolean {
    return this.timezonesList.includes(timezone);
  }

  public getTimezones(): string[] {
    return moment.tz.names();
  }

  public setSelectedStores(storeIds:any) {
    let stores = [];

    storeIds.forEach(storeId => {
      const store  = this._contextInfo.stores.find(x => x.storeId == storeId);
      stores.push(store)
    });

    if(stores.length > 0){
      this.sessionStorageService.set(this.SELECTEDSTORES, stores,false);
    }
  }

  public getSelectedStores() {
    const stores = this.sessionStorageService.get(this.SELECTEDSTORES,null,false);
    if(stores){
      return stores;
    }
    return null;
  }

  public isSignalRMessagingEnabled() {
    const user = this.userContext();
    if(user.signalRMessagingEnabled){
      return true;
    }
    return false;
  }

}
