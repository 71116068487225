import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';

export interface IConfirmationModel {
  title: string;
  message: string;
  btnOkText?: string;
  btnCancelText?: string;
  dialogSize?: 'sm' | 'lg';
  showOkButton?: boolean;
}

@Injectable()
export class ConfirmationDialogService {

  isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private result: Subject<any> = new Subject<any>();
  model: IConfirmationModel;

  constructor() { }

  confirm(confirmationModel: IConfirmationModel, resultCallback?: Function): void{

    console.log(confirmationModel);
    // defaults
    confirmationModel.btnOkText = confirmationModel.btnOkText ? confirmationModel.btnOkText : 'OK';
    confirmationModel.btnCancelText = confirmationModel.btnCancelText ? confirmationModel.btnCancelText : 'Cancel';
    confirmationModel.dialogSize = confirmationModel.dialogSize ? confirmationModel.dialogSize : 'sm';
    confirmationModel.showOkButton = confirmationModel.showOkButton !== undefined ? confirmationModel.showOkButton : true;
    this.model = confirmationModel;
    this.isVisible.next(true);
    this.result.subscribe(result => {
      resultCallback ? resultCallback(result) : (result) => { console.log(result); }
    });
    // return this.result.asObservable();
  }

  dismiss(): void {
    this.isVisible.next(false);
  }

  close(result: any): void {
    this.isVisible.next(false);
    this.result.next(result);
  }

}