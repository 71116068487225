import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from './context-service';
import { ProxyService } from './proxy-service';
import { environment } from '../../../environments/environment';

type JsonStringifyReplacer = (key: string, value: any) => any;

@Injectable({
    providedIn: 'root'
})
export class TattletaleService {

    private readonly LOG_URL = 'https://logging-bridge.shopperkit.com/api/v1/logging/log';

    constructor(private httpClient: HttpClient,
        private contextService: ContextService,
        private proxyService:ProxyService) {
    }

	public log(message?: any, ...optionalParams: any[]) {
        try {
            console.log(message, ...optionalParams);
		    this.sendToPapertrail(message, ...optionalParams);
        }
        catch {
            // nom nom
        }
	}

    private sendToPapertrail(message?: any, ...optionalParams: any[]) {
        if (message instanceof HttpResponse && message.url === this.LOG_URL) {
            return;
        }

		let content = this.serialize(message);
		if (optionalParams && optionalParams.length) {
			for (const element of optionalParams) {
				content += ' ' + this.serialize(element);
			}
		}

		const body = {
			System: 'CommandCenterUI - ' + environment.envCode,
			Message: content
		};

		console.log(body);

		const headers = new HttpHeaders();
		headers.append('X-Requested-With', 'XMLHttpRequest');
		headers.append('Content-Type', 'application/json');

		this.httpClient.post(this.LOG_URL, body, { headers })
			.toPromise()
			.then(response => { })
			.catch(error => { });
    }

    private serialize(value: any): string {
        switch (typeof value) {
            case 'string':
            case 'number':
            case 'boolean':
                return value.toString();
            default:
                return JSON.stringify(value, this.circularReplacer());
        }
    }

    private circularReplacer(): JsonStringifyReplacer {
        const set = new WeakSet();
        return (key: string, value: any): any => {
            if (typeof value === 'object' && value !== null) {
                if (set.has(value)) {
                    return;
                }
                set.add(value);
            }
            return value;
        };
    }
}
