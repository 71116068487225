
<a class="nav-logo" (click)="nav.gotoNavLink('/')">
    <img alt="logo"
         src="../../../../assets/scss/themes/shopperkit/nav-logo-default.svg"
         ngx-tooltip
         [tooltipType]="'tooltip'"
         [tooltipTemplate]="tooltipLogo" />
    <ng-template #tooltipLogo>
        <span class="tooltip-label"></span>
        <span class="tooltip-value">Home</span>
        <span class="tooltip-label"></span>
    </ng-template>
</a>
<span class="nav-client">
    <span>{{ clientName }}</span>
</span>
<a class="menu-item" 
   (click)="nav.gotoNavLink('/home')"
   [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like', '/home')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Home</span>
    </div>
</a>
<a class="menu-item" *ngIf="userIsAManagerOrCommandCenterOnly"
   (click)="nav.gotoNavLink('/orders')"
   [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like','/orders')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Orders</span>
    </div>
</a>
<a class="menu-item" *ngIf="userIsAManagerOrCommandCenterOnly && !isCurbsideOnly"
   (click)="nav.gotoNavLink('/inventory')"
   [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like','/inventory')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Inventory</span>
    </div>
</a>
<a class="menu-item" *ngIf="userIsAManager"
   (click)="nav.gotoNavLink('/events')"
   [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like','/events')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Events</span>
    </div>
</a>
<a class="menu-item" *ngIf="userIsAManagerOrCommandCenterOnly && !isCurbsideOnly"
    [class.menu-item-active]="nav.isNavMenuItemOpened('comms')"
    (click)="nav.openNavMenuItem('comms')" >
    <div>
        <i class="fa fa-bars"></i>
        <span>Communications</span>
    </div>
</a>
<div class="sub-menu-container" *ngIf="userIsAManagerOrCommandCenterOnly && !isCurbsideOnly"
    [class.sub-menu-hidden]="!nav.isNavMenuItemOpened('comms')"
    [class.sub-menu-visible]="nav.isNavMenuItemOpened('comms')">
    <a class="sub-menu-item" (click)="nav.gotoNavLink('/communications/chats')" >&#9679; Chats</a>
    <a class="sub-menu-item" *ngIf="userIsAClientAdmin" (click)="nav.gotoNavLink('/communications/text-templates')">&#9679; Templates</a>
    <a class="sub-menu-item" (click)="nav.gotoNavLink('/communicationshub')">&#9679; Hubs</a>
</div>
<a class="menu-item"  *ngIf="userIsAManager"
   (click)="nav.gotoNavLink('/stagedorders')"
   [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like','/stagedorders')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Staged Orders</span>
    </div>
</a>
<a class="menu-item" *ngIf="userIsAManagerOrCommandCenterOnly && !isCurbsideOnly"
   (click)="nav.gotoNavLink('/orderarchives')"
   [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like','/orderarchives')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Order Archives</span>
    </div>
</a>
<a class="menu-item" *ngIf="showLabolMenuItem && userIsAManagerOrCommandCenterOnly && !isCurbsideOnly" 
    (click)="nav.gotoNavLink('/labor')"
    [class.menu-item-highlighted]="nav.isNavMenuItemHighLighted('like','/labor')">
    <div>
        <i class="fa fa-bars"></i>
        <span>Labor Management</span>
    </div>
</a>
