<div class="header">

  <div #mainHeader class="header__main">
    <div class="pl-3">
      <button class="btn btn-primary text-secondary" *ngIf="!nav.isNavMenuVisible && contextService.loggedIn" (click)="nav.show()"
              type="button" ngx-tooltip [tooltipType]="'tooltip'" [tooltipTemplate]="tooltip">
        <ng-template #tooltip>
          <span class="tooltip-label"></span>
          <span class="tooltip-value">Show Menu</span>
          <span class="tooltip-label"></span>
        </ng-template>
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <button class="btn btn-warning text-secondary" *ngIf="nav.isNavMenuVisible && contextService.loggedIn" (click)="nav.hide()"
              type="button" ngx-tooltip [tooltipType]="'tooltip'" [tooltipTemplate]="tooltip">
        <ng-template #tooltip>
          <span class="tooltip-label"></span>
          <span class="tooltip-value">Hide Menu</span>
          <span class="tooltip-label"></span>
        </ng-template>
        <i class="fa fa-fw fa-arrow-left"></i>
      </button>
    </div>

    <div class="mr-auto">
      <img class="logo" (click)="navigateToHomeScreen()" *ngIf="!nav.isNavMenuVisible || !contextService.loggedIn" src="../../../../assets/images/shopperkit-logotype-white.png"
           ngx-tooltip [tooltipType]="'tooltip'" [tooltipTemplate]="tooltip" />
      <ng-template #tooltip>
        <span class="tooltip-label"></span>
        <span class="tooltip-value">Home</span>
        <span class="tooltip-label"></span>
      </ng-template>
    </div>

    <!--utility button template - Alternate hyperlink, etc - to another widget / page -->
    <div class="pr-3" *ngIf="contextService.loggedIn && nav.headerIsUtilityButtonUsed">
      <ng-template [ngTemplateOutlet]="nav.headerUtilityButtonTemplate"></ng-template>
    </div>

    <div class="pr-3" *ngIf="contextService.loggedIn && headerIsFullscreenAllowedTemp">
      <div ngbDropdown placement="bottom-right">
        <button class="btn text-secondary btn-team" ngbDropdownToggle ngx-tooltip [tooltipType]="'tooltip'" [tooltipTemplate]="tooltip">
          <ng-template #tooltip>
            <span class="tooltip-label"></span>
            <span class="tooltip-value">{{ 'Toggle to Fullscreen View' }}</span>
            <span class="tooltip-label"></span>
          </ng-template>
          <i class="fa fa-fw fa-expand"></i>
        </button>
        <div ngbDropdownMenu>
          <a ngbDropdownItem (click)="screenFullService.turnOn(true)">On Device</a>
          <a ngbDropdownItem (click)="screenFullService.turnOn()">In Browser</a>
        </div>
      </div>
    </div>

    <!--control side panel visibility-->
    <div class="pr-3" *ngIf="contextService.loggedIn && sidePanel.isUsed">
      <button class="btn text-secondary" [ngClass]="sidePanel.isVisible? 'btn-primary':'btn-warning'" type="button" (click)="sidePanel.toggle()"
              ngx-tooltip [tooltipType]="'tooltip'" [tooltipTemplate]="tooltip">
        <ng-template #tooltip>
          <span class="tooltip-label"></span>
          <span class="tooltip-value">{{ sidePanel.isVisible? 'Hide Side Panel':'Show Side Panel' }}</span>
          <span class="tooltip-label"></span>
        </ng-template>
        <i class="fa fa-fw fa-filter"></i>
      </button>
    </div>

    <!--user account dropdown-->
    <div class="pr-3" *ngIf="contextService.loggedIn">
      <div ngbDropdown placement="bottom-right">
        <button class="btn btn-primary text-secondary" ngbDropdownToggle ngx-tooltip [tooltipType]="'tooltip'"
                [tooltipTemplate]="tooltip">
          <ng-template #tooltip>
            <span class="tooltip-label"></span>
            <span class="tooltip-value">Account</span>
            <span class="tooltip-label"></span>
          </ng-template>
          <i [class]="userRoleBadgeIconClass"></i>
          {{ userNameFormatted }}
        </button>
        <div ngbDropdownMenu>
          <a class="dropdown-item" (click)="logout()">Log Out</a>
          <div class="dropdown-divider"></div>
          <div ngbDropdownItem>{{ clientName }}</div>
          <div ngbDropdownItem>{{ userName }}</div>
        </div>
      </div>
    </div>

    <!--header page title - Absolute Position ==> place this item last-->
    <div class="header-nav-title" *ngIf="nav.headerTitle">
      <span class="title text-secondary">{{ nav.headerTitle }}</span>
    </div>
  </div>

  <!--subheader component-->
  <div #subHeader *ngIf="showSubHeader && subHeaderTemplate" class="header__sub">
    <ng-template [ngTemplateOutlet]="subHeaderTemplate"></ng-template>
  </div>

</div>
