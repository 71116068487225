import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOrderStatusHistory } from 'src/app/@core/models/order-status-history';
import * as moment from 'moment';
import { OrderStatusObjName } from 'src/app/@core/enums/order-status.enum';

@Component({
  selector: 'sk-order-status-history-modal',
  styleUrls: ['./order-status-history-modal.component.scss'],
  templateUrl: './order-status-history-modal.component.html',
})
export class OrderStatusHistoryModalComponent implements OnInit {

  @Input() public items: IOrderStatusHistory[] = [];
  @Input() public orderSourceKey: string;
  filteredList: IOrderStatusHistory[] = [];

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
    // Preveting to show older statuses before than newer statuses
    const sorted = this.items.sort((it1, it2) => it2.orderStatusHistoryId  - it1.orderStatusHistoryId);
    sorted.forEach(r => {
      if (this.filteredList.every(rr => rr.newStatusName != r.newStatusName)) {
        r.height = '42px';
        this.filteredList.push(r);
      }   
    });    
  }

  accept() {
    this.modalService.dismissAll();
  }

  dismiss() {
    this.modalService.dismissAll();
  }

  isCompleted(item: IOrderStatusHistory): boolean {
    return item.newStatusName.toLowerCase() === 'done';
  }

  isFirstItem(item: IOrderStatusHistory): boolean {
    return this.filteredList.findIndex(it => it.orderStatusHistoryId === item.orderStatusHistoryId) === this.filteredList.length - 1;
  }

  isMiddleItem(item: IOrderStatusHistory): boolean {    
    return !this.isFirstItem(item) && !this.isLastItem(item);
  }

  isLastItem(item: IOrderStatusHistory): boolean {
    return this.filteredList.length > 1 && this.filteredList.findIndex(it => it.orderStatusHistoryId === item.orderStatusHistoryId) === 0;
  }

  isSingleItem(): boolean {
    return this.filteredList.length === 1;
  }

  getFormatedDate(item: IOrderStatusHistory): string {
    return `${moment(item.createdDate).format('dddd, MMM DD, YYYY')} AT ${moment(item.createdDate).format('LT')}`;
  }

  showTopBorder(item: IOrderStatusHistory): boolean {
    return (this.filteredList.length > 1 && this.isFirstItem(item)) || this.isMiddleItem(item);
  }

  showBottomBorder(item: IOrderStatusHistory): boolean {
    return this.isLastItem(item) || this.isMiddleItem(item);
  }

  showFilledCircle(item: IOrderStatusHistory): boolean {
    return this.isCompleted(item) || (this.filteredList.length > 1 && this.isFirstItem(item)) || this.isMiddleItem(item);
  }

}
