
export const OrderStatusEnum = {
  New: 1,
  PrepStarted: 1.2,
  HubInProgress: 1.4,
  HubPicked: 1.41,
  HubStaged: 1.42,
  HubClearForPos: 1.49,
  HubPosInProgress: 1.5,
  HubPosResponseReceived: 1.51,
  HubPosResponseSent: 1.52,
  HubReadyForLoading: 1.6,
  HubLoading: 1.61,
  HubReadyForShipping: 1.62,
  HubShipped: 1.7,
  SpokeReceiving: 1.8,
  SpokeReceived: 1.81,
  InProgress: 2,
  Picked: 3,
  Staged: 4,
  ClearForPOS: 4.9,
  POSInProgress: 5,
  POSReqReceived: 6,
  POSResponseSent: 7,
  POSDone: 8,
  Loaded: 9,
  Cancelled: 10,
  DeliveryInProgress: 11,
  Done: 12
};


export const OrderStatusNameEnum = {
  Cancelled: 'cancelled',
  Done: 'done'
};

export const OrderStatusObjName = {
  'new': 1,
  'prep started': 1.2,
  'hub in progress': 1.4,
  'hub picked': 1.41,
  'hub staged': 1.42,
  'hub staged - clear for pos': 1.49,
  'hub pos in progress': 1.5,
  'hub pos response received': 1.51,
  'hub pos response sent': 1.52,
  'hub ready for loading': 1.6,
  'hub loading': 1.61,
  'hub ready for shipping': 1.62,
  'hub shipped': 1.7,
  'spoke receiving': 1.8,
  'spoke received': 1.81,
  'in progress': 2,
  'in process': 2,
  'picked': 3,
  'staged': 4,
  'staged - clear for pos': 4.9,
  'clear for pos': 4.9,
  'pos in progress': 5,
  'pos in process': 5,
  'pos request received': 6,
  'pos response sent': 7,
  'pos done': 8,
  'loaded': 9,
  'cancelled': 10,
  'delivery in progress': 11,
  'delivery in process': 11,
  'done': 12
};
