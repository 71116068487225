﻿import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggerService {

  constructor() {
    console.log(`environment: ${environment.envCode}`);
  }

  logMessage(message: string, override?: boolean) {
    if (override || environment.debug) console.log(message);
  }
  logData(data: any, override?: boolean) {
    if (override || environment.debug) console.log(data);
  }

  logException(exception: any) {
    console.log(exception);
  }
}
