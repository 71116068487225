
<ng-template #modal>
  <div class="modal d-block">
    <div class="modal-dialog" [ngClass]="{'modal-lg': sizeLg, 'modal-dialog-centered': centered}">
        <div class="modal-content" [ngClass]="{'bg-white': !bgTransparent}">
            <div *ngIf="showHeader" class="modal-header">
              <h6>{{title}}</h6>
            </div>
            <div class="modal-body" [ngClass]="{'no-padding': noPadding}">
              <ng-template *ngIf="template" [ngTemplateOutlet]="template"></ng-template>
            </div>
            <div *ngIf="showFooter" class="modal-footer">
              <div *ngIf="!hideCancelBtn" class="btn" (click)="cancel()">{{cancelLabel}}</div>
              <div class="btn" [ngClass]="{'disabled': disabled}" (click)="select()">{{selectLabel}}</div>
            </div>
        </div>
    </div>
  </div>
</ng-template>
