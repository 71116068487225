import { Component, OnInit, OnDestroy, DoCheck, TemplateRef, ElementRef } from '@angular/core';
import { NavService } from '../../services/nav-service';
import { SidePanelService } from '../../services/side-panel-service';
import { sideWidth } from '../animations/animations.sidePanel';

@Component({
  selector: 'sk-filter-container',
  templateUrl: './filterContainer.html',
  styleUrls: ['./filterContainer.scss'],
})
export class FilterContainerComponent implements OnInit, OnDestroy, DoCheck {
  template: TemplateRef<any> = null;
  constructor(
    public nav: NavService,
    public sidePanelService: SidePanelService,
    private elementRef: ElementRef
  ) {}

  ngDoCheck(): void {
    this.template = this.nav.filterTemplate;
    let offsetWidth = '0px';
    if (this.template && this.sidePanelService.isVisible) {
      offsetWidth = sideWidth();
    }
    this.elementRef.nativeElement.style.setProperty('--var-width', offsetWidth);
  }

  ngOnDestroy() {
  }

  ngOnInit() {
  }

}
