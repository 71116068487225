import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'sk-cc',
  templateUrl: 'app.html',
})
export class AppComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
