import { Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';
import { ScreenFullService } from '../../services/screenfull-service';

@Component({
    selector: 'sk-full-screen-tab',
    templateUrl: './fullScreenTab.html',
    styleUrls: ['./fullScreenTab.scss'],
})
export class FullScreenTabComponent implements OnInit, OnDestroy {

    constructor(
        public screenFullService: ScreenFullService
    ) {
        
    }

    ngOnDestroy() {
       
    }

    demoSetNextStartDate() {
       
    }

    ngOnInit() {
    }

}
