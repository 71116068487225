﻿export const environment = {
    debug: false,
    envCode: 'PROD',
    isProd: true,
    version: '1.2.0',

    overrideUsernameAndPassword: false,
    username: '',
    password: '',

    adminPortalUrl: 'https://admin.shopperkit.com/',
    adminPortalForgottenPasswordPath: 'forgotyourpassword',

    //BaseApiUrl: "http://fulfiller.webapi.commandcenter/api/v1/",
   // BaseApiCoreUrl: 'http://apiuat.fulflr.com/api/v1/',

    //BaseMessagingServiceUri: 'https://messaginguat.shopperkit.com/api/v1/',
   // MessagingWebSocketUri: 'wss://messaginguat.shopperkit.com/api/v1/websocket/',

    BaseApiUrl: "https://apicommandcenter.shopperkit.com/api/v1/",
    BaseApiCoreUrl: "https://api.shopperkit.com/api/v1/",

    BaseMessagingServiceUri: 'https://messaging.shopperkit.com/api/v1/',
    BaseMessagingServiceUriSignalr: 'https://messaging-prod.shopperkit.com/api/v1/',
    MessagingWebSocketUri: 'wss://messaging.shopperkit.com/api/v1/websocket/',
    MessagingSignalRUri: "https://messaging-prod.shopperkit.com/api/v1/signalr/",

    idleTimeout: 24 * 60 * 60, // seconds
    idleTimeoutActionCountdown: 30, // seconds
    idleAuthCheckThreshold: 10, // seconds

    authTokenValidityDuration: 30, // minutes

    testingToggles: {
        filterDates: false,
    },

    featureToggles: {
        examplesArea: false,
        inventoryInspectorDashboard: false
    }
}
