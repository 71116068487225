import { Component, OnInit } from '@angular/core';
import { AuthConfig, OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { ContextService } from '../../@core/services/context-service';
import { AuthenticationService } from '../../@core/services/authentication-service';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../@core/services/spinner-service';
import { ILoginSetting } from 'src/app/@core/models/login';
import { LoggerService } from '../../@core/services/logger-service';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { NavService } from 'src/app/@core/services/nav-service';
import { TattletaleService } from 'src/app/@core/services/tattletale-service';
// import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  templateUrl: 'oauth.html',
  styleUrls: ['./oauth.scss']
})
export class OAuthComponent implements OnInit, OnDestroy {
  errorMsgShow = false;
  errorMsg = '';
  private routeSubs: Subscription;
  private oauthEventSubs: Subscription;
  constructor(
    private oauthService: OAuthService,
    private contextService: ContextService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    private loggerService: LoggerService,
    private nav: NavService,
    private tattle: TattletaleService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.spinnerService.show();

    this.oauthEventSubs = this.oauthService.events.subscribe((event: OAuthEvent) => {
      this.tattle.log('OAuth::events.subscribe Event:', event);
      if (event.type === 'token_received') {
      }
    });

    this.routeSubs = this.activatedRoute.queryParams.subscribe(async params => {
      let loginSettings: ILoginSetting = null;
      if (params['clientId']) {
        const clientId = +params['clientId'];
        this.spinnerService.setMessage('Loading login settings');
        loginSettings = await this.authenticationService.getLoginSettings(clientId);
      } else {
        this.spinnerService.setMessage('Validating user credentials in shopperkit portal');
        loginSettings = this.contextService.getLoginLoginSettings();
      }
      if (loginSettings && loginSettings.useOAuth && loginSettings.apps && loginSettings.apps
        .some(app => app.appType === 'commandcenter')) {
        this.setOAuthServiceSettins(loginSettings);
      } else {
        const clientId = this.contextService.getClientId();
        if (clientId) {
          this.router.navigate(['/login'], { queryParams : { clientId: clientId, login: 1}});
        } else {
          this.errorMsgShow = true;
          this.errorMsg = 'Login setting for current client could not be found.';
        }
        this.spinnerService.hide();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubs) {
      this.routeSubs.unsubscribe();
    }
    this.oauthEventSubs.unsubscribe();
  }

  private setOAuthServiceSettins(loginSettings: ILoginSetting) {
    const ccApp = loginSettings.apps.find(app => app.appType === 'commandcenter');
    const clientId = this.contextService.getClientId();
    const authCodeFlowConfig: AuthConfig = {
      issuer: loginSettings.authorizationBaseUrl,
      // logoutUrl: window.location.origin + `/login?clientId=${clientId}&login=1`,
      redirectUri: window.location.origin + '/oauthlogin', // ccApp.redirectUrl,
      clientId: ccApp.appId,
      scope: loginSettings.scope,
      responseType: ccApp.responseType,
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true
    };
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint
    this.oauthService.loadDiscoveryDocument(loginSettings.baseUrl).then(async (_) => {
        this.oauthService.issuer = loginSettings.authorizationBaseUrl;
        return await this.oauthService.tryLoginCodeFlow();
      }).then(async (_) => {
        if (!this.oauthService.hasValidAccessToken()) {
          this.oauthService.initCodeFlow();
        } else {
          const accessToken = this.oauthService.getAccessToken();
          // console.log('Access Token:', accessToken);
          // const helper = new JwtHelperService();
          // const decodedToken = helper.decodeToken(accessToken);
          // console.log('Access Token Decoded:', decodedToken);
          const response = await this.validateAccessToken(clientId, accessToken);
          this.spinnerService.hide();
          if (!response) {
           this.setGenericError();
          }
        }
      }).catch((err) => {
        this.tattle.log('OAuth::loadDiscoveryDocument Error:', err);
        if (this.userHasRequestedPasswordReset(err)) {
          // In this case we need to enter a different flow on the Azure AD B2C side.
          // This is still a valid Code + PKCE flow, but uses a different form to support self service password reset
          // Add this to the state as we need it on our way back
          this.oauthService.initCodeFlow('PASSWORD_RESET');
        } else {
          // Another error has occurred, e.g. the user cancelled the reset-password flow.
          // In that case, simply retry the login.
          this.oauthService.initCodeFlow();
        }
        this.spinnerService.hide();
        this.setGenericError();
      });
  }

  private setGenericError() {
    this.errorMsgShow = true;
    this.errorMsg = 'User credentials could not be validated in shopperkit portal.';
  }

  private userHasEnteredPasswordResetFlow(): boolean {
    return window.location.search.indexOf('PASSWORD_RESET') > -1;
  }

  private userHasRequestedPasswordReset(err: OAuthErrorEvent): boolean {
    return err && err.params && (err.params['error_description'] as string).startsWith('AADB2C90118');
  }

  private async validateAccessToken(clientId: any, accessToken: string): Promise<boolean> {
    try {
      const loginResponse = await this.authenticationService.loginWithAccessToken(clientId, accessToken);
      this.tattle.log('OAuth::validateAccessToken Response:', loginResponse);
      if (loginResponse.isSuccess) {
        this.errorMsgShow = false;
        this.contextService.setOAuthLoginIsActive(true);
        this.nav.gotoNavLink('/');
        return true;
      } else {
        return false;
      }
    } catch (e) {
      this.tattle.log('OAuth::validateAccessToken Error:', e);
      this.loggerService.logException(e);
      return false;
    }
  }
}
