﻿
export class UserStore {
  public storeId: number;
  public name: string;
  public state: string;
  public cityTown: string;
  public storeNumber: string;
  public storePhoneNumber: string;
  public consolidatedApprovalIsActive?: boolean;
  public timeZoneName: string;
  public minTimeIntervalInMinutesForPromiseTimeUpdate: number;
  public selected?: boolean;  
  public signalRMessagingEnabled?: boolean;
}
