import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDeliveryStatusHistoryResponse, IDeliveryStatusHistory } from 'src/app/@core/models/delivery-status-history';
import { OrderService } from 'src/app/@core/services/order-service';

@Component({
  selector: 'sk-delivery-status-history-modal',
  styleUrls: ['./delivery-status-history-modal.component.scss'],
  templateUrl: './delivery-status-history-modal.component.html',
})
export class DeliveryStatusHistoryModalComponent implements OnInit {

  @Input() public deliveryStatusHistory: IDeliveryStatusHistoryResponse;
  @Input() public orderSourceKey: string;
  items: IDeliveryStatusHistory[] = [];
  retrySent = false;
  retrying = false;

  constructor(
    private modalService: NgbModal,
    private orderService: OrderService) {
  }

  ngOnInit() {
    const items = this.deliveryStatusHistory && this.deliveryStatusHistory.orderDeliveryStatusHistoryList || [];
    items.forEach(it => {
      it.height = '42px';
    });
    this.items = items.sort((a, b) => { return b.orderDeliveryStatusHistoryId - a.orderDeliveryStatusHistoryId });
  }

  accept() {
    this.modalService.dismissAll();
  }

  dismiss() {
    this.modalService.dismissAll();
  }

  isCompleted(item: IDeliveryStatusHistory): boolean {
    return item.newOrderDeliveryStatus.toLowerCase() === 'done' 
      || item.newOrderDeliveryStatus.toLowerCase() === 'completed' 
      || item.newOrderDeliveryStatus.toLowerCase() === 'delivered';
  }

  isFirstItem(item: IDeliveryStatusHistory): boolean {
    return this.items.findIndex(it => it.orderDeliveryStatusHistoryId === item.orderDeliveryStatusHistoryId) === this.items.length - 1;
  }

  isMiddleItem(item: IDeliveryStatusHistory): boolean {    
    return !this.isFirstItem(item) && !this.isLastItem(item);
  }

  isLastItem(item: IDeliveryStatusHistory): boolean {
    return this.items.length > 1 && this.items.findIndex(it => it.orderDeliveryStatusHistoryId === item.orderDeliveryStatusHistoryId) === 0;
  }

  isSingleItem(): boolean {
    return this.items.length === 1;
  }
   
  getFormatedDate(item: IDeliveryStatusHistory): string {
    return `${moment(item.createdDate).format('dddd, MMM DD, YYYY')} AT ${moment(item.createdDate).format('LT')}`;
  }

  showTopBorder(item: IDeliveryStatusHistory): boolean {
    return (this.items.length > 1 && this.isFirstItem(item)) || this.isMiddleItem(item);
  }

  showBottomBorder(item: IDeliveryStatusHistory): boolean {
    return this.isLastItem(item) || this.isMiddleItem(item);
  }

  showFilledCircle(item: IDeliveryStatusHistory): boolean {
    return this.isCompleted(item) || (this.items.length > 1 && this.isFirstItem(item)) || this.isMiddleItem(item);
  }

  async retry() {
    this.retrying = true;
    this.retrySent = true;
    this.deliveryStatusHistory.deliveryFailed = false;
    try {
      const orderId = this.deliveryStatusHistory.orderDeliveryStatusHistoryList[0].orderId;
      const storeId = this.deliveryStatusHistory.orderDeliveryStatusHistoryList[0].storeId;
      await this.orderService.sendDeliveryRetry(storeId, orderId);
      this.retrying = false;
    } catch {
      this.retrying = false;
    }
  }

  toggleExpanded(item: IDeliveryStatusHistory, elem: any) {
    item.expanded = !item.expanded;
    if (item.expanded) {
      setTimeout(()=>{
        const calculatedHeight = elem ? `${((elem.clientHeight - 15) / 2)}px` : '42px';
        item.height = calculatedHeight;
      }, 100);
    } else {
      item.height = '42px';
    }    
  }

  showDeliveryProvider(item: IDeliveryStatusHistory): boolean {
    return item.expanded && item.deliveryProviderSourceKey && item.deliveryProviderSourceKey != 'N/A';
  }
}
