import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, DoCheck } from '@angular/core';
import { SidePanelService } from '../../services/side-panel-service';

@Component({
  selector: 'sk-side-panel',
  styleUrls: ['./sidePanel.scss'],
  templateUrl: './sidePanel.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent implements OnInit, DoCheck, OnDestroy {

  constructor(
    public sidePanel: SidePanelService,
    public cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.sidePanel.sidePanelChangeDetector = this.cdr;
  }

  ngDoCheck(): void {
    const padding = this.sidePanel.getPadding();
    const backgroundColor = this.sidePanel.getBackgroundColor() || 'gainsboro';
    this.elementRef.nativeElement.style.setProperty('--var-padding', (padding === null || padding === undefined) ? '10px' :
      padding.toString() + 'px');
    this.elementRef.nativeElement.style.setProperty('--var-bgcolor', backgroundColor);
  }

  ngOnDestroy() {
  }

  demoSetNextStartDate() {
  }

  ngOnInit() {
  }
}
