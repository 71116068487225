﻿import { Injectable } from '@angular/core';
import { Howl } from 'howler';

import { LoggerService } from './logger-service';

@Injectable()
export class SoundService {

    soundInstance: any;
    soundDirectoryBase = '../../../assets/sounds';

    constructor(private loggerService: LoggerService) {
    }

    playFile(soundFileName: string, soundDirectoryBase?: string): void {
        // nothing will happen if something is misspelled in the file name
        const soundDirectory = soundDirectoryBase || this.soundDirectoryBase;
        const soundFile = soundFileName || 'buzzer2.wav';
        const soundFilePath = `${soundDirectory}/${soundFile}`;
        this.soundInstance = new Howl({
            src: [soundFilePath]
        });
        this.soundInstance.play();
    }

    test(): void {
        this.soundInstance = new Howl({
            src: [`${this.soundDirectoryBase}/buzzer2.wav`]
        });
        this.soundInstance.play();
    }
}
