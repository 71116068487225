
<button *ngIf="screenFullService.isFullscreen"
        class="btn text-team btn-disabled"
        type="button" (click)="screenFullService.turnOff()"
        ngx-tooltip
        [tooltipType]="'tooltip'"
        [tooltipTemplate]="tooltipExitFullScreen">
    <ng-template #tooltipExitFullScreen>
        <span class="tooltip-label"></span>
        <span class="tooltip-value">{{ 'Toggle to Normal View' }}</span>
        <span class="tooltip-label"></span>
    </ng-template>
    <i class="fa fa-fw fa-compress"></i>
</button>