﻿import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core'; // this lib needs to updated if going to angular 6
import { ContextService } from './context-service';
import { LoggerService } from './logger-service';
import { Constants } from '../constants';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class IdleListenerService {

    isShowing = false;
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    timeBeforeLogoff: number;
    logoffCallback: Function;
    private readonly idleServiceSubject = new BehaviorSubject<boolean>(false);
    constructor(
        private loggerService: LoggerService,
        private contextService: ContextService,
        private idle: Idle,
        private constants: Constants
    ) {
        const timeoutDuration = environment.idleTimeout || 5; // seconds
        const timeoutActionCountdownDuration = environment.idleTimeoutActionCountdown || 30; // seconds;
        const timeoutAuthCheckThreshold = (environment.idleAuthCheckThreshold || 5) * 1000; // milliseconds

        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(timeoutDuration);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(timeoutActionCountdownDuration);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // idle.onInterrupt.subscribe(() => {
        //    // this fires only on continuous interrupt tripping (continuous actions by user)
        //    // this is not quite as useful as checking when active via timer
        //    // because the auth will not be checked if the user is not active ...
        //    const now = Date.now();
        //    const difference = now - this.lastAuthCheck;
        //    if (difference > timeoutAuthCheckThreshold) {
        //        this.loggerService.logMessage(difference.toString());
        //        this.lastAuthCheck = now;
        //        this.contextService.isUserTokenExpired();
        //    }
        // });
        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!';
            this.loggerService.logMessage(this.idleState);
            this.isShowing = true;
            idle.clearInterrupts();
        });
        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            this.loggerService.logMessage(this.idleState);
        });
        //
        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!!!!!!!!!!';
            this.timedOut = true;
            // this.loggerService.logMessage(this.idleState);
            this.logoffCallback();
        });
        idle.onTimeoutWarning.subscribe((countdown: number) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!';
            // this.loggerService.logMessage(this.idleState);
            this.timeBeforeLogoff = countdown;
        });
    }

    get idleAnimationState() {
        return this.isShowing ? 'in' : 'out';
    }

    startReset(logoffCallback?: Function) {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.isShowing = false;
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
        this.logoffCallback = logoffCallback ? logoffCallback : this.logoffCallback;
    }

    logoff() {
        this.idleServiceSubject.next(true);
        this.logoffCallback();
        this.stopClear();
    }

    stopClear() {
        this.isShowing = false;
        this.idle.stop();
        this.logoffCallback = null;
    }

    public getIdleServiceObservable() {
      return this.idleServiceSubject.asObservable();
    }

}
