import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@Component({
  selector: 'sk-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    private confirmationDialogService: ConfirmationDialogService
    ) { }

  ngOnInit() {
  }

  get model() {
    return this.confirmationDialogService.model;
  }

  get isVisible() {
    return this.confirmationDialogService.isVisible;
  }

  decline() {
    this.confirmationDialogService.close(false);
  }

  accept() {
    this.confirmationDialogService.close(true);
  }

  dismiss() {
    this.confirmationDialogService.dismiss();
  }

}