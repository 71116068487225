﻿import { OrderMessage } from "./order-message";

export class OrderCommunicationSummary {
    public orderId: number;
    public clientId: number;
    public storeId: number;    
    public orderSourceKey: string;
    public customer: string;
    public shoppers: string;
    public totalMessages: number;
    public totalMessagesFromCustomer: number;
    public latestMessageDateTimeUTC: Date;
    public latestMessages: OrderMessage[];
    public selected: boolean;
    public hasNew: boolean; //updated via gui
    public newMessage: string //updated via gui

    constructor() {
        this.latestMessages = new Array<OrderMessage>();
    }
}