import { Component, OnInit, Input, TemplateRef, Output } from '@angular/core';
import { NgbModal, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'events';

export type ProgressBarType = 'success' | 'info' | 'warning' | 'danger';

@Component({
  selector: 'sk-progressbar-modal',
  styleUrls: ['./progressbar-modal.component.scss'],
  templateUrl: './progressbar-modal.component.html',
})
export class ProgressBarModalComponent implements OnInit {
  @Input() public title: string;
  @Input() public value: number;
  @Input() public progressMessage: string;
  @Input() public isCompleted: boolean;
  @Input() public centered = false;
  @Input() public template: TemplateRef<any> = null;
  @Input() public showOkButton = false;
  @Input() public okTextButton = 'Ok'; 
  @Input() public type: ProgressBarType = 'success';  
  @Output() public okClick = new EventEmitter();  
  constructor(
    private modalService: NgbModal,
    private config: NgbProgressbarConfig) {
      this.config.height = '2rem';
  }

  ngOnInit() {
  }

  accept() {
    try {
      this.okClick.emit('Ok Clicked');
      this.dismiss();
    } catch {
    }
  }

  dismiss() {
    this.modalService.dismissAll();
  }
}
