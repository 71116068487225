
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppCoreRoutingModule } from './core.routing';
import { AppSharedModule } from '../@shared/shared.module';
import { FeaturesModule } from '../@features/features.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from './constants';
import { CanActivateGuard } from './core.authguard';
import { AuthenticationService } from './services/authentication-service';
import { SpinnerService } from './services/spinner-service';
import { LoggerService } from './services/logger-service';
import { TattletaleService } from './services/tattletale-service';
import { ContextService } from './services/context-service';
import { ProxyService } from './services/proxy-service';
import { NavService } from './services/nav-service';
import { SidePanelService } from './services/side-panel-service';
import { TranslationService } from './services/translation-service';
import { LookupService } from './services/lookup-service';
import { NotificationService } from './services/notification-service';
import { SessionStorageService } from './services/session-storage-service';
import { LocalStorageService } from './services/local-storage-service';
import { ProxyInterceptorService } from './services/proxy-interceptor';
import { SocketService } from './services/socket-service';
import { CommunicationService } from './services/communication-service';
import { IdleListenerService } from './services/idle-listener-service';
import { XlsxService } from './services/xlsx-service';
import { ScreenFullService } from './services/screenfull-service';
import { SoundService } from './services/sound-service';
import { MasterComponent } from './components/master/master';
import { NavComponent } from './components/nav/nav';
import { HeaderComponent } from './components/header/header';
import { ContentComponent } from './components/content/content';
import { SpinnerComponent } from './components/spinner/spinner';
import { SidePanelComponent } from './components/sidePanel/sidePanel';
import { SidePanelTabComponent } from './components/sidePanel/sidePanelTab';
import { FullScreenTabComponent } from './components/fullScreenTab/fullScreenTab';
import { IdleListenerComponent } from './components/idleListener/idleListener';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';
import { OrderService } from './services/order-service';
import { FilterContainerComponent } from './components/filterContainer/filterContainer';
import { CustomModalDialogComponent } from './components/custom-modal-dialog/custom-modal-dialog.component';
import { CustomDialogService } from './services/custom-dialog-service';
import { ColorUtilService } from './services/colorutil-service';
import { SignalRService } from './services/signalr-service'; 

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    AppCoreRoutingModule,
    AppSharedModule,
    FeaturesModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  declarations: [
    MasterComponent,
    NavComponent,
    ContentComponent,
    SpinnerComponent,
    HeaderComponent,
    SidePanelComponent,
    SidePanelTabComponent,
    IdleListenerComponent,
    FullScreenTabComponent,
    ConfirmationDialogComponent,
    FilterContainerComponent,
    CustomModalDialogComponent
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: ProxyInterceptorService, multi: true }],
    TranslationService,
    {
      provide: APP_INITIALIZER,
      deps: [TranslationService, ProxyService],
      useFactory: (translationServ: TranslationService) => () => translationServ.init(),
      multi: true
    },
    SocketService,       
    CommunicationService,
    ContextService,
    ProxyService,
    AuthenticationService,
    SpinnerService,
    LoggerService,
    TattletaleService,
    Constants,
    CanActivateGuard,
    LookupService,
    NotificationService,
    NavService,
    SessionStorageService,
    LocalStorageService,
    SidePanelService,
    IdleListenerService,
    XlsxService,
    ScreenFullService,
    ConfirmationDialogService,
    // {
    //    provide: APP_INITIALIZER,
    //    deps: [ScreenFullService],
    //    useFactory: (s: ScreenFullService) => () => s.appOnInit(),
    //    multi: true
    // },
    SoundService,
    OrderService,
    CustomDialogService,
    ColorUtilService,
    SignalRService
  ],
  exports: [
    MasterComponent,
    AppCoreRoutingModule
  ],
  entryComponents: []
})
export class AppCoreModule { }
