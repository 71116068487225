import * as signalR from '@microsoft/signalr';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ContextService } from "./context-service";
import { environment } from '../../../environments/environment';
import { ContextInfo } from "src/app/@shared/models";

interface ISignalRConnectionInfo {
    url: string;
    accessToken: string;
}

export interface ISignalRGroupsResponse {
    groups: ISignalRGroupItem[];
};

export interface ISignalRGroupItem {
    signalRTargetMethod: string;
    groupNames: string[]; 
};

@Injectable({
    providedIn: "root"
})
export class SignalRService {
    constructor(   
        private contextService : ContextService,
        private _http: HttpClient        
    ) { 
    }

    public connected: boolean;
    private _baseUrl: string;
    private _user: ContextInfo;
    private _hubConnection: signalR.HubConnection;
    private _starting = false;   
    private _targetMethods: string[] = []; 
    private _groups: string[] = [];
    private _carryoutTaken: Subject<any> = new Subject();
    private _customer: Subject<any> = new Subject();
    private _smsUpdate: Subject<any> = new Subject();
    private _kiokReady: Subject<any> = new Subject();
    private _chatMessage: Subject<any> = new Subject();
    private _commandCenterEvent: Subject<any> = new Subject();
    private _stagedOrder: Subject<any> = new Subject();
    private _customerHere: Subject<any> = new Subject();
    private _customerOnMyWay: Subject<any> = new Subject();
    private _prepSectionCustomerHere: Subject<any> = new Subject();
    private _prepSectionCustomerOnMyWay: Subject<any> = new Subject();
    private _deliveryDriverHere: Subject<any> = new Subject();
    private _generic: Subject<any> = new Subject();
    private _leadTimeAlert: Subject<any> = new Subject();
    private _echo: Subject<any> = new Subject();

    private readonly listenersMap: {[key: string]: (str: any) => void} = {        
        'CarryoutTaken': (msg: any) => this.notifierFunct('CarryoutTaken', this._carryoutTaken, msg),
        'Customer': (msg: any) => this.notifierFunct('Customer', this._customer, msg),
        'SmsUpdate': (msg: any) => this.notifierFunct('SmsUpdate', this._smsUpdate, msg),
        'KiokReady': (msg: any) => this.notifierFunct('KiokReady', this._kiokReady, msg),
        'ChatMessage': (msg: any) => this.notifierFunct('ChatMessage', this._chatMessage, msg),
        'CommandCenterEvent': (msg: any) => this.notifierFunct('CommandCenterEvent', this._commandCenterEvent, msg),
        'StagedOrder': (msg: any) => this.notifierFunct('StagedOrder', this._stagedOrder,msg),
        'CustomerHere': (msg: any) => this.notifierFunct('CustomerHere', this._customerHere, msg),
        'CustomerOnMyWay': (msg: any) => this.notifierFunct('CustomerOnMyWay', this._customerOnMyWay, msg),
        'PrepCustomerHere': (msg: any) => this.notifierFunct('PrepCustomerHere', this._prepSectionCustomerHere, msg),
        'PrepCustomerOnMyWay': (msg: any) => this.notifierFunct('PrepCustomerOnMyWay', this._prepSectionCustomerOnMyWay, msg),
        'DeliveryDriverHere': (msg: any) => this.notifierFunct('DeliveryDriverHere', this._deliveryDriverHere, msg),
        'Generic': (msg: any) => this.notifierFunct('Generic', this._generic, msg),
        'LeadTimeAlert': (msg: any) => this.notifierFunct('LeadTimeAlert', this._leadTimeAlert, msg),
        'Echo': (msg: any) => this.notifierFunct('Echo', this._echo, msg),
    };

    private readonly notifierFunct = (listener: string, notifier : Subject<string>, msg: any) : void => {
        if (this._targetMethods.some(m => m == listener)) {
            return;
        }
        this._targetMethods.push(listener);
        console.log(`SignalRService: ${listener} message received: ${JSON.stringify(msg)}`);
        notifier.next(msg);
    } 

    public onCarryoutTaken = () => this._carryoutTaken;
    public onCustomer = () => this._customer;
    public onSmsUpdate = () => this._smsUpdate;
    public onKiokReady = () => this._kiokReady;
    public onChatMessages = () => this._chatMessage;
    public onCommandCenterEvent = () => this._commandCenterEvent;
    public onStagedOrder = () => this._stagedOrder;
    public onCustomerHere = () => this._customerHere;
    public onCustomerOnMyWay = () => this._customerOnMyWay;
    public onPrepSectionCustomerHere = () => this._prepSectionCustomerHere;
    public onPrepSectionCustomerOnMyWay = () => this._prepSectionCustomerOnMyWay;
    public onDeliveryDriverHere = () => this._deliveryDriverHere;
    public onGeneric = () => this._generic; 
    public onLeadTimeAlert = () => this._leadTimeAlert;    
    public onEcho = () => this._echo;

    private async getConnectionInfo(): Promise<ISignalRConnectionInfo> {
        const user = this.contextService.userContext();
		if (!user || !user.token) {
			return null;
		}
        const requestUrl = `${this._baseUrl}negotiate`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'x-sk-signalr-userid': user.userId,
                'Authorization': 'fulfiller ' + user.token                 
            }
        };
        const response = this._http.post<ISignalRConnectionInfo>(requestUrl, null, options);
        return await response.toPromise();
    }

    private initSettings() {
        if (!this._baseUrl) {            
            this._baseUrl = environment.MessagingSignalRUri;           
        }
        this._user = this.contextService.userContext();
    }  

    public isOpened(): boolean {
		return this._hubConnection && this._hubConnection.state == signalR.HubConnectionState.Connected;
	}

    public async connect(): Promise<any> {        
		if (!this.isOpened()) {
			await this.start();
		} else {
            await this.rejoinToStoreGroups();
        }
	}

    public async disconnect():Promise<void> {
        try {
            if (this.isOpened()){
                this.removeFromAllGroups();
                await this._hubConnection.stop(); 
            }        
        } catch(err) {
            console.error('SignalRService Error disconnecting:' + JSON.stringify(err));  
        }
	}

    private async initConnection() {
        try {
            this.initSettings();
            const connectionInfo = await this.getConnectionInfo();

            console.log('SignalRService connection response:' + JSON.stringify(connectionInfo));

            const options = {
                accessTokenFactory: () => connectionInfo.accessToken
            };

            const retry: signalR.IRetryPolicy = {
              nextRetryDelayInMilliseconds: (retryContext: any) => {
                return 3000; // Every 3 seconds tries to reconnect
              }
            }; 

            this._hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(connectionInfo.url, options)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect(retry)
                .build();

            this._hubConnection.onreconnected(async (connectionId?: string) => {
               console.log('SignalRService: SignalR reconnected for connection:' + (connectionId || '')); 
               await this.rejoinToStoreGroups();
            });

            this._hubConnection.onreconnecting((error?: any)=> {
                console.log('SignalRService: SignalR reconnecting event!!!!!'); 
            });

            this._hubConnection.onclose((error?: any) => {
                console.log('SignalRService: SignalR closed event!!!!!'); 
                this.onConnectionClosed(error);
            });
        } catch (err) {
            console.log('SignalRService initConnection Error:' + JSON.stringify(err));
        }
    }

    private onConnectionClosed(error:Error):void {
		// reconnect after 4-8 seconds
		// note start method has a built in exponential backoff
        const loggedIn = this.contextService.loggedIn;
		if (!loggedIn) {
            return;
        }
        console.error('SignalRService Error: onConnectionClosed Error!!!!');        
		//setTimeout(async () => { await this.start(Number.MAX_VALUE, 500); }, this.getRandomInterval(3*1000, 8*1000));
	}  

    private async start(): Promise<void> {
		try {  
            if(this._starting) {
                return;
            }                    		
            const loggedIn = this.contextService.loggedIn;
			if (!loggedIn) {
                console.error('SignalRService Error: User is not logged in');
				return;
			}
            console.log('SignalRService: Starting!!!!');
            this._starting = true;
			await this.initConnection();

            if (!this._hubConnection) {
                console.error('SignalRService: hubConnection Error');
            }

            this._hubConnection.start()
            .then(() => {
                console.log('SignalRService: hubConnection OK');        
                this._starting = false;                        
            })
            .catch(err => {
                console.error('SignalRService Error: Hub Connection Error:' + JSON.stringify(err));
                this._starting = false;
            });           
		}
		catch(e) {
            console.error('SignalRService Error: Starting Hub Connection:' + JSON.stringify(e)); 
            this._starting = false; 
		}        
	}

    async rejoinToStoreGroups() { 
        if (this._starting) {
            return;
        }  
        const loggedIn = this.contextService.loggedIn;     
        if (loggedIn) {
            return;
        }
        const stores = this.contextService.getSelectedStores();
        if(!stores || stores.length == 0){
            return;
        }
        this._starting = true;
        const user = this.contextService.userContext();  
        const storeIds = stores.map((s: any) => s.storeId.toString());
        await this.addToGroups(storeIds, user.userId);
        this._starting = false;
    }

    async setStores(storeIds: string[]){
        this.contextService.setSelectedStores(storeIds);
        await this.addToGroups(storeIds, this._user.userId);
    }

    async addToGroups(storeIds: string[], userId: string): Promise<void> {
        try {
            if (!this.isOpened()) {
                console.error('SignalRService addToGroups Error: User is not connected');
                return;
            }
            this.joinedGroups(); 
            await this._hubConnection.send('AddToGroups', 'SKCOMMANDCENTER', userId, storeIds);
        } catch (err) {
            console.error('SignalRService addToGroups Error:' + JSON.stringify(err));
        }
    }

    private joinedGroups() {
        try {
            this._hubConnection.off('Groups');
            this._hubConnection.on('Groups', async (message: any) => {
                console.log('SignalRService Groups:' + JSON.stringify(message));
                const user = this.contextService.userContext();
                const loggedIn = this.contextService.loggedIn;
                if (!user || !loggedIn) {
                    return;
                }
                const stores = await this.contextService.getSelectedStores();
                if (!stores || stores.length == 0) {
                    return;
                }
                this.terminateAll();
                this._groups = message.GroupNames;
                stores.forEach((store: any) => { 
                    this._groups.forEach(g => {
                        try {
                            if (g.startsWith('SKCOMMANDCENTER-' + store.storeId + '-')) {
                                const targetMethod = g.replace('SKCOMMANDCENTER-' + store.storeId + '-', '');
                                if (targetMethod.includes('-') && targetMethod.endsWith('CustomerHere')) {
                                    this._hubConnection.on('PrepCustomerHere', this.listenersMap['PrepCustomerHere']);
                                }
                                if (targetMethod.includes('-') && targetMethod.endsWith('CustomerOnMyWay')) {
                                    this._hubConnection.on('PrepCustomerOnMyWay', this.listenersMap['PrepCustomerOnMyWay']);
                                }
                                if (!targetMethod.includes('-')) {
                                    this._hubConnection.on(targetMethod, this.listenersMap[targetMethod]);
                                }                                                        
                                console.log('SignalRService TargetStoreMethod:' + targetMethod);
                            }
                            if (g.startsWith('SKCOMMANDCENTER-' + user.userId + '-')) {
                                const targetMethod = g.replace('SKCOMMANDCENTER-' + user.userId + '-', '');
                                this._hubConnection.on(targetMethod, this.listenersMap[targetMethod]);                             
                                console.log('SignalRService TargetUserMethod:' + targetMethod);
                            }
                        } catch(err) {
                            console.error('SignalRService joinedGroups error:' + JSON.stringify(err));
                        }
                    });
                });        
            });
            
        } catch(err) {
            console.error('SignalRService joinedGroups error:' + JSON.stringify(err));
        }
    }

    private terminateAll() {
        this._targetMethods.forEach(g => {
            try {
                console.log('SignalRService User removed from:' + g);
                this._hubConnection.off(g);
            } catch {                
            }          
        });
        this._targetMethods = [];
    }

    removeFromAllGroups() {
        try {
            if (this._groups.length == 0) {
                console.log('SignalRService removeFromAllGroups: No groups were found');
                return;  
            }
            if (!this.isOpened()) {
                console.error('SignalRService removeFromAllGroups Error: User is not connected');
                return;
            }            
            this.terminateAll();
            this._hubConnection.send('RemoveFromGroups', this._groups);
            this._groups = [];
        } catch(err) {
            console.error('SignalRService removeFromAllGroups error:' + JSON.stringify(err));
        }
    }

}
