import { Component, OnInit } from '@angular/core';
import { NavService } from '../../@core/services/nav-service';
import { AuthenticationService } from '../../@core/services/authentication-service';
import { ContextService } from '../../@core/services/context-service';

@Component({
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit {
  storeId: number;
  showLabor = false;  
  userIsAManager = false;
  userIsAManagerOrCommandCenterOnly = false;
  userIsCurbsideOnly = false;
  constructor(
    private nav: NavService,
    private authenticationService: AuthenticationService,
    private contextService: ContextService
  ) {
  }

  async ngOnInit() {
    this.userIsAManager = this.contextService.isAManager() && !this.contextService.isCommandCenterOnly();
    this.userIsAManagerOrCommandCenterOnly = this.contextService.isAManager() || this.contextService.isCommandCenterOnly();
    this.userIsCurbsideOnly = this.contextService.isCurbsideOnly();
    this.nav.header('Home');
    this.nav.disallowFullscreen();
    this.showLabor = await this.nav.checkLaborLicense(); 
    this.showLabor = await this.nav.checkLaborLicense();    
  }

  onClickTile(path: string) {
    return this.nav.gotoNavLink(path);
  }

  logout() {
    this.authenticationService.logoff();
  }

  isCommandCenterOnlyUser(){
    return this.contextService.isCommandCenterOnly();
  }

  isAManager(){
    return this.contextService.isAManager();
  }

  isCurbsideOnly() {
    return this.contextService.isCurbsideOnly();
  }

  async onNavigate(){    
    const laborUrl = await this.nav.getLaborUrl();
    window.open(laborUrl, "_blank");
  }
}
