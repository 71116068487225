import { TemplateRef } from '@angular/core';

export interface ICustomDialog {
  actionType: CustomDialogActionType;
  title?: string;
  bodyTemplate?: TemplateRef<any>;
  cancelLabel?: string;
  selectlabel?: string;
  sizeLg?: boolean;
  centered?: boolean;
  selectBtnDisabled?: boolean;
  contextData?: any;
  hideCancelBtn?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  noBodyPadding?: boolean;
  parentContainer?: any;
  bgTransparent?: boolean;
}

export enum CustomDialogAction {
  Select = 1,
  Cancel,
  SelectBtnDisabled,
  Show,
  Hide
}

export type CustomDialogActionType = typeof CustomDialogAction.Select | typeof CustomDialogAction.Cancel |
  typeof CustomDialogAction.SelectBtnDisabled | typeof CustomDialogAction.Show | typeof CustomDialogAction.Hide;

export interface ICustomDialogAction {
  type: CustomDialogActionType;
  data?: any;
}
