﻿
import {Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { ContextService } from './context-service';
import { ContextInfo } from "../../@shared/models/context-info";
import { SpinnerService } from "./spinner-service";
import { LoggerService } from "./logger-service";

@Injectable()
export class ProxyInterceptorService implements HttpInterceptor {

    constructor(
        private contextService: ContextService,
        private spinnerService: SpinnerService,
        private loggerService: LoggerService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req) 
            .pipe(map(event => {               
                //this.loggerService.logData(event);
                if (event instanceof HttpResponse) {
                    //this.loggerService.logMessage('response');
                    this.loggerService.logData(event);
                }
                return event;
            }));
          
    }

}