﻿import { Injectable } from '@angular/core';
import { ContextInfo } from '../../@shared/models/context-info';

@Injectable()
export class LocalStorageService {
  clientId: number;
  userName: string;
  setStorageContext(userContext: ContextInfo) {
    this.clientId = userContext.clientId;
    this.userName = userContext.userName;
  }
  getEncodedKey(key: string) {
    const encoded = `skCc:${this.clientId}:${this.userName}:${key}`;
    return encoded;
  }
  set(key: string, obj: any, useEncodedKey: boolean = true) {
    const encodedKey = useEncodedKey ? this.getEncodedKey(key) : key;
    window.localStorage.setItem(encodedKey, JSON.stringify(obj));
  }
  get(key: string, defaultValue?: any, useEncodedKey: boolean = true): any {
    const encodedKey = useEncodedKey ? this.getEncodedKey(key) : key;
    const stringified = window.localStorage.getItem(encodedKey);
    try {
      let rawValue = JSON.parse(stringified);
      // could do some more checks here, too
      if (Array.isArray(defaultValue) && !rawValue) {
        rawValue = [...defaultValue] || null;
      }
      return rawValue;
    } catch (err) {
      return defaultValue;
    }
  }
  remove(key: string, useEncodedKey: boolean = true): void {
    const encodedKey = useEncodedKey ? this.getEncodedKey(key) : key;
    window.localStorage.removeItem(encodedKey);
  }

}
