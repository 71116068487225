﻿import { Injectable } from '@angular/core';
import * as screenfull from 'screenfull';

import { SidePanelService } from './side-panel-service';
import { NavService } from './nav-service';
import { LoggerService } from './logger-service';

@Injectable()
export class ScreenFullService {

  isFullscreen = false;
  isDeviceFullscreenMode: boolean;
  isLoaded: boolean;

  constructor(
    private sidePanel: SidePanelService,
    private nav: NavService,
    private loggerService: LoggerService,
  ) { }

  appOnInit() {
    if (!this.isLoaded) {
      this.loggerService.logMessage('initializing screenfull service');
      screenfull.on('error', (event: any) => {
        this.loggerService.logException({ message: 'Failed to enable fullscreen', event });
      });
      screenfull.on('change', () => {
        this.loggerService.logMessage(`Am I fullscreen? ${screenfull.isFullscreen ? 'Yes' : 'No'}`);
        this.isFullscreen = screenfull.isFullscreen;
        this.isFullscreen ? this.animateExitFullscreen() : this.animateEnterFullscreen();
        // this.sidePanel.hide();
      });
      this.isLoaded = true;
    }
  }

  turnOn(isDeviceFullscreen?: boolean) {
    this.appOnInit();
    this.isDeviceFullscreenMode = isDeviceFullscreen;
    if (this.isDeviceFullscreenMode) {
      if (screenfull.enabled) {
        screenfull.request();
      }
    } else {
      this.isFullscreen = true;
      this.animateEnterFullscreen();
    }
  }

  turnOff() {
    this.appOnInit();
    if (this.isDeviceFullscreenMode) {
      if (screenfull.enabled) {
        screenfull.exit();
      }
    } else {
      this.isFullscreen = false;
      this.animateExitFullscreen();
    }
    this.isDeviceFullscreenMode = false;
  }

  private animateExitFullscreen() {
    this.sidePanel.hide();
    this.nav.hide();
  }
  private animateEnterFullscreen() {
    this.sidePanel.hide();
    this.nav.hide();
  }

  get screenfullAnimationState(): string {
    return this.isFullscreen ? 'full' : 'normal';
  }
}
