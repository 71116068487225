import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ProxyService } from "../../@core/services/proxy-service";
import { Constants } from "../../@core/constants";
import { SocketService } from '../../@core/services/socket-service';
import { SignalRService } from '../../@core/services/signalr-service';
import { ContextService } from '../../@core/services/context-service';
import { OrderCommunicationSummary, OrderMessage, UserStore } from '../../@shared/models';

@Injectable()
export class CommunicationService {
    private chatMessageSubscriber: Subscription;
    constructor(
        private proxyService: ProxyService,
        private constants: Constants,
        private socketService: SocketService,
        private signalRService: SignalRService,
        private contextService: ContextService
    )
    {
        this.init();        
    }

    private _communications: OrderCommunicationSummary[];
    get communications(): OrderCommunicationSummary[] {
        return this._communications;
    }

    clearCommunications() {
        this._communications = new Array<OrderCommunicationSummary>();
    }

    loadCommunications(stores: UserStore[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            var request = {
                storeIds: stores.map((s) => {
                    return s.storeId
                }),
                withCommunicationsOnly: true
            };

            this.proxyService.post('communication/orderCommunicationSummary', request).then((communicationSummary: any) => {
                this._communications = communicationSummary;
                resolve(communicationSummary);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    public init():void {
        this._communications = new Array<OrderCommunicationSummary>();
        if(!this.contextService.isSignalRMessagingEnabled()){            
            this.chatMessageSubscriber = this.socketService.messages.subscribe((data: any) => {
                this.messageReceived(data);
            });
        }
        else{
            this.chatMessageSubscriber = this.signalRService.onChatMessages().subscribe((msg: any) => {
                this.messageReceived(msg);
            });
        }        
    }

    messageReceived(data: any) {
        if (data.Type !== this.constants.MessageTypeCustomerMessaging) {
            return;
        }

        //add message to model
        let summary = this._communications.find((c: OrderCommunicationSummary) => c.orderId == data.OrderId);

        //capitals on property names from server here. on purpose
        const orderMessage: OrderMessage = new OrderMessage();
        orderMessage.orderMessageId = data.OrderMessageId;
        orderMessage.messageText = data.MessageText;
        orderMessage.createdDate = data.CreatedDate;
        orderMessage.createdByUserId = data.CreatedByUserId;
        orderMessage.imageUri = data.ImageUri;
        orderMessage.fromName = data.FromName;
        orderMessage.isFromUser = data.IsFromUser;

        if (summary) {
            summary.latestMessages.push(orderMessage);
            summary.latestMessageDateTimeUTC = data.CreatedDate;
            if (!summary.totalMessages) {
                summary.totalMessages = 0;
            }
            summary.totalMessages++;
            if (!summary.totalMessagesFromCustomer) {
                summary.totalMessagesFromCustomer = 0;
            }
            if (!data.IsFromUser)
                summary.totalMessagesFromCustomer++;
            summary.hasNew = true;
        } else { //new one, new conversation
            summary = new OrderCommunicationSummary();
            summary.customer = data.CustomerName;
            summary.shoppers = data.Shoppers;
            summary.storeId = data.StoreId;
            summary.orderId = data.OrderId;
            summary.orderSourceKey = data.OrderSourceKey;
            summary.latestMessages.push(orderMessage);
            summary.latestMessageDateTimeUTC = data.CreatedDate;
            summary.totalMessages = 1;
            if (!summary.totalMessagesFromCustomer) {
                summary.totalMessagesFromCustomer = 0;
            }
            if (!data.IsFromUser)
                summary.totalMessagesFromCustomer = 1;
            else
                summary.totalMessagesFromCustomer = 0;

            summary.hasNew = true;
            this._communications.push(summary);
        }
     }

    /*stock test data*/
    getConversation(): Array<OrderMessage> {
        let retVal: Array<OrderMessage> = new Array<OrderMessage>();

        let message: OrderMessage = new OrderMessage();
        message.orderMessageId = 15;
        message.messageText = "We are out of kiwi fruit, would you like lemons instead or peaches?";
        message.fromName = "Tim Smith";
        message.createdByUserId = "1234";
        message.createdDate = new Date();
        message.isFromUser = false;

        retVal.push(message);

        let message2: OrderMessage = new OrderMessage();
        message2.orderMessageId = 27;
        message2.messageText = "Thanks, peaches sound great.  Are they Georgia peaches?";
        message2.fromName = "Customer";
        message2.createdByUserId = "1234";
        message2.createdDate = new Date();
        message2.isFromUser = true;

        retVal.push(message2);
        let message3: OrderMessage = new OrderMessage();
        message3.orderMessageId = 42;
        message3.messageText = "They are from right here in Spalding County, GA!";
        message3.fromName = "Tim Smith";
        message3.createdByUserId = "1234";
        message3.createdDate = new Date();
        message3.isFromUser = false;
        retVal.push(message3);

        let message4: OrderMessage = new OrderMessage();
        message4.orderMessageId = 53;
        message4.messageText = "My goodness that is great!  Give me 4 pounds of fresh ones!";
        message4.fromName = "Customer";
        message4.createdByUserId = "1234";
        message4.createdDate = new Date();
        message4.isFromUser = true;

        retVal.push(message4);

        return retVal;
    }
}
