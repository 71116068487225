import { Component, DoCheck, OnInit, OnDestroy } from '@angular/core';
import {
  pageAnimatedContent, pageAnimatedHeader,
  navAnimatedMenu, navAnimatedHeader,
  sideAnimatedPanel, sideAnimatedContent,
  fullscreenAnimatedContent, fullscreenAnimatedHeader
} from '../animations';
import { NavService } from '../../services/nav-service';
import { SidePanelService } from '../../services/side-panel-service';
import { IdleListenerService } from '../../services/idle-listener-service';
import { ScreenFullService } from '../../services/screenfull-service';
import { filterAnimatedContent, filterAnimatedPanel } from '../animations/animations.filterContainer';

@Component({
  templateUrl: 'master.html',
  styleUrls: ['./master.scss'],
  selector: 'app-master',
  animations: [
    filterAnimatedPanel, filterAnimatedContent,
    pageAnimatedContent, pageAnimatedHeader,
    navAnimatedMenu, navAnimatedHeader,
    sideAnimatedPanel, sideAnimatedContent,
    fullscreenAnimatedContent, fullscreenAnimatedHeader
  ]
})
export class MasterComponent implements DoCheck, OnInit, OnDestroy {
  sideAnimationStateTemp: string;
  filterContainerVisible = false;
  constructor(
    public nav: NavService,
    public sidePanel: SidePanelService,
    public idleListener: IdleListenerService,
    public screenFullService: ScreenFullService
  ) {}

  ngOnInit() {
    window.addEventListener('resize', this.resizeEvent, false);
    window.addEventListener('orientationchange', this.orientationchangeEvent, false);
  }

  resizeEvent = (event: any) => {
    this.sidePanel.updateViewComponents();
  }

  orientationchangeEvent = (event: any) => {
    this.sidePanel.updateViewComponents();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeEvent, false);
    window.removeEventListener('orientationchange', this.orientationchangeEvent, false);
  }

  // this solves the issue with value changed after checked (this was a persistent one)
  ngDoCheck() {
    // console.log('checking', this.sidePanel.sideAnimationState1);
    if (this.sidePanel.sideAnimationState !== this.sideAnimationStateTemp) {
      this.sideAnimationStateTemp = this.sidePanel.sideAnimationState;
    }
    this.filterContainerVisible = this.nav.showFilterContainer;
  }

}
