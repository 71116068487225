<div class="modal d-block">
    <div class="modal-dialog">
        <div class="modal-content" style="border-top-width: 0px !important; border-bottom-width: 0px !important;">
            <div class="modal-header">
                <h4 class="modal-title">{{title}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <div *ngIf="mode === MODE.Cancel || mode === MODE.CancelMultiple" class="form-group center col-sm-12 green-border-focus">
                <input #orderNumber class="form-control" [(ngModel)]="orderInput" [placeholder]="inputPlaceholderComment" required>
              </div>
              <div *ngIf="mode !== MODE.UpdatePromiseTime && mode !== MODE.UpdatePromiseTimeMultiple" class="form-group center col-sm-12 green-border-focus">
                <textarea #text class="form-control" rows="3" [(ngModel)]="orderComment" [placeholder]="placeholderComment" required></textarea>
              </div>
              <div *ngIf="mode === MODE.UpdatePromiseTime || mode === MODE.UpdatePromiseTimeMultiple" class="form-group center col-sm-12 green-border-focus flex-column align-items-center">
                <div class="d-flex justify-content-center">
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [readonly]="true" [(ngModel)]="model" [startDate]="model" [minDate]="minDate" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="onDateChange($event)">
                    <div class="input-group-append">
                      <button class="btn" (click)="d.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                  <ngb-timepicker [(ngModel)]="time" [readonlyInputs]="true" [meridian]="true" [minuteStep]="30" (ngModelChange)="onTimeChange($event)"></ngb-timepicker>
                </div>
                <div class="d-flex">
                  <div *ngIf="timeValueIsCorrect == false" class="text-danger">* {{errorMessage}}</div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="decline()">Cancel</button>
              <button type="button" [disabled]="dataIsNotCorrect()" class="btn btn-primary" (click)="accept()">Ok</button>
            </div>
        </div>
    </div>
</div>
