
<div class="content">
    <div class="content__tile" (click)="onClickTile('/communications')" *ngIf="userIsAManagerOrCommandCenterOnly && !userIsCurbsideOnly">
        <div class="content__tile__header tileYellow"><i class="fa fa-fw fa-3x fa-comments"></i></div>
        <div class="content__tile__body">Communications</div>
    </div>
    <div class="content__tile tileRose" (click)="onClickTile('/orders')" *ngIf="userIsAManagerOrCommandCenterOnly">
        <div class="content__tile__header"><i class="fa fa-fw fa-3x fa-shopping-cart"></i></div>
        <div class="content__tile__body">Orders</div>
    </div>
    <div class="content__tile tileGreenLite" (click)="onClickTile('/inventory')" *ngIf="userIsAManagerOrCommandCenterOnly && !userIsCurbsideOnly">
        <div class="content__tile__header"><i class="fa fa-fw fa-3x fa-server"></i></div>
        <div class="content__tile__body">Inventory</div>
    </div>
    <!--
    <div class="content__tile tilePurple" (click)="onClickTile('./labor')">
        <div class="content__tile__header"><i class="fa fa-fw fa-3x fa-users"></i></div>
        <div class="content__tile__body">Labor</div>
    </div>-->
    <div class="content__tile tileOrange" (click)="onClickTile('/events')" *ngIf="userIsAManager">
        <div class="content__tile__header"><i class="fa fa-fw fa-3x fa-calendar"></i></div>
        <div class="content__tile__body">Events</div>
    </div>
   <div class="content__tile tileGreenStrong" (click)="onClickTile('/stagedorders')" *ngIf="userIsAManager">
        <div class="content__tile__header"><i class="fa fa-fw fa-3x fa-shopping-basket"></i></div>
        <div class="content__tile__body">Staged Orders</div>
    </div>
    <div class="content__tile tileRed" (click)="onClickTile('/orderarchives')" *ngIf="userIsAManagerOrCommandCenterOnly && !userIsCurbsideOnly">
        <div class="content__tile__header"><i class="fa fa-archive fa-3x" aria-hidden="true"></i></div>
        <div class="content__tile__body">Order Archives</div>
    </div>
    <div class="content__tile tileRed" (click)="onNavigate()" *ngIf="showLabor && userIsAManagerOrCommandCenterOnly && !userIsCurbsideOnly">
        <div class="content__tile__header"><i class="fa fa-fw fa-3x fa-users"></i></div>
        <div class="content__tile__body">Labor Management</div>
    </div>   
</div>
