﻿import { Injectable } from '@angular/core';
@Injectable()
export class SpinnerService {

  isShowing = false;
  message = '';
  styles: any = {};

  show(message: string = '') {
    if (message) {
      this.message = message;
    }
    this.isShowing = true;
  }

  hide() {
    this.isShowing = false;
  }

  setMessage(msg: string) {
    this.message = msg;
  }
}
