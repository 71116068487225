<div class="margin-top container fill">
  <div class="row">
    <form *ngIf="!useOAuth" #loginForm="ngForm" (ngSubmit)="onLogin($event)" autocomplete="false">
      <div class="form-group" [ngClass]="{'has-error' : (!userNameField.valid && userNameField.dirty) || (!userNameField.valid && userNameField.touched)}">
        <input type="text" class="form-control" id="userName" required [(ngModel)]="userName" name="userName"
          #userNameField="ngModel" placeholder="{{translationService.translate('Email')}}" />
        <p class="help-block" *ngIf="userNameField.errors?.required && userNameField.touched">{{translationService.translate('*Email cannot be blank')}}</p>
        <p class="help-block" *ngIf="userNameField.errors?.pattern && userNameField.touched">{{translationService.translate('*Must be a valid email address')}}</p>
      </div>
      <div class="form-group" [ngClass]="{'has-error' : (!passwordField.valid && passwordField.dirty) || (!passwordField.valid && passwordField.touched)}">
        <input type="password" class="form-control" id="password" required [(ngModel)]="password" name="password"
          #passwordField="ngModel" placeholder="{{translationService.translate('Password')}}" maxlength="20" />
        <p class="help-block" *ngIf="passwordField.errors?.required && passwordField.touched">
          {{translationService.translate('*Password cannot be Blank')}}</p>
      </div>
      <div *ngIf="errorMsgShow && !loading">
        <span class="text-center" style="color:red;">{{errorMsg}}</span>
      </div>
      <div class="form-group col-d-offset-1">
        <p><a [attr.href]="forgotMyPasswordLink()">{{translationService.translate('Forgot Password?')}}</a></p>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid || loading">
          <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="loading">&nbsp;{{translationService.translate('Log In')}}...</span>
          <span *ngIf="!loading">{{translationService.translate('Log In')}}</span>
        </button>
      </div>
    </form>

    <div *ngIf="useOAuth && showJustLoginBtn" class="form-group">
      <button type="submit" class="btn btn-primary" (click)="initOAuthLogin()">
        <span>{{translationService.translate('INITIATE_LOGIN')}}</span>
      </button>
    </div>
  </div>
</div>
