﻿import { Injectable, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ContextService } from './context-service';
import { ProxyService } from './proxy-service';
import { SessionStorageService } from './session-storage-service';

@Injectable()
export class NavService {

  pageAnimationState: string;
  navAnimationState: string;
  isNavMenuVisible = false;
  activeNavMenuId: string = null;
  headerTitle: string = null;
  headerActions: any[] = [];
  headerChangeDetector: ChangeDetectorRef;
  headerIsUtilityButtonUsed = false;
  headerIsFullscreenAllowed = false;
  headerUtilityButtonTemplate: TemplateRef<any>;
  subHeaderTemplate: TemplateRef<any> = null;
  filterTemplate: TemplateRef<any> = null;
  showFilterContainer = false;
  subHeaderHeight = 0;
  showSubHeader = false;
  footerEnabled = true;
  contentIsScrollable = true;
  contentScrollTop: string;
  offsetTop = 0;
  storeId: number;
  menuButtonEmitter = new Subject<boolean>();
  userIsLoggedIn$ = new Subject<boolean>();
  constructor(
    private router: Router,
    private proxyService: ProxyService,
    private contextService: ContextService,
    private sessionStorageService: SessionStorageService
  ) {
    this.pageAnimationState = 'in';
    this.navAnimationState = 'out';
  }

  onPageAnimationStart(event: any) {
    // this.loggerService.logMessage(event);
  }

  onPageAnimationDone(event:  any) {
    // this.loggerService.logMessage(event);
    if (event.toState === 'out') {
      this.pageAnimationState = 'in';
    }
  }

  updateHeaderComponent() {
    this.headerChangeDetector.detectChanges();
  }
  useHeaderUtilityButton(template: TemplateRef<any>) {
    this.headerIsUtilityButtonUsed = true;
    this.headerUtilityButtonTemplate = template;
    this.updateHeaderComponent();
  }
  useSubHeaderTemplate(template: TemplateRef<any>) {
    this.showSubHeader = true;
    this.subHeaderTemplate = template;
    this.updateHeaderComponent();
  }
  clearSubHeaderTemplate() {
    this.showSubHeader = false;
    this.subHeaderTemplate = null;
    this.offsetTop = 0;
    this.subHeaderHeight = 0;
    this.updateHeaderComponent();
  }
  subHeaderIsVisible(): boolean {
    return this.showSubHeader;
  }
  getSubHeaderTemplate(): TemplateRef<any> {
    return this.subHeaderTemplate;
  }
  clearHeaderUtilityButton() {
    this.headerIsUtilityButtonUsed = false;
    this.headerUtilityButtonTemplate = null;
    this.updateHeaderComponent();
  }
  allowFullscreen() {
    this.headerIsFullscreenAllowed = true;
    this.updateHeaderComponent();
  }
  disallowFullscreen() {
    this.headerIsFullscreenAllowed = false;
    this.updateHeaderComponent();
  }
  header(title: string) {
    this.headerTitle = title;
    this.updateHeaderComponent();
  }
  hideFooter() {
    this.footerEnabled = false;
  }
  showFooter() {
    this.footerEnabled = true;
  }
  toggle() {
    this.isNavMenuVisible ? this.hide() : this.show();
  }
  hide() {
    this.navAnimationState = 'out';
    this.isNavMenuVisible = false;
    this.activeNavMenuId = null;
    this.menuButtonEmitter.next(false);
  }
  show() {
    this.navAnimationState = 'in';
    this.isNavMenuVisible = true;   
    this.menuButtonEmitter.next(true);
  }

  gotoNavLink(routeName: string) {
    this.pageAnimationState = 'out';
    this.hide();
    this.router.navigate([routeName]);
  }

  isNavMenuItemHighLighted(matchType: string, routeName: any) {
    if (matchType === 'is') {
      return this.router.url === routeName;
    }
    if (matchType === 'like') {
      return this.router.url.includes(routeName);
    }
    if (matchType === 'in') {
      // routeName is an array here
      return routeName.indexOf(this.router.url) > -1;
    }
  }

  isNavMenuItemOpened(name: string) {
    if (this.activeNavMenuId) {
      return (name === this.activeNavMenuId);
    }
    return false;
  }
  openNavMenuItem(name:  string) {
    this.activeNavMenuId = name;
    // this.activeNavMenuId = this.activeNavMenuId === ngEl.id ? null : ngEl.id;
  }

  //
  contentScrollable(isScrollable: boolean) {
    this.contentIsScrollable = isScrollable;
  }

  async getLaborUrl(): Promise<string> {
    const user = this.contextService.userContext();
    this.storeId = user.stores[0].storeId;
    return await this.proxyService.getCore('labor/uiurl', this.storeId);     
  }
  
  async checkLaborLicense(): Promise<boolean> {
    try {
      if (!this.contextService.loggedIn) {
        return false; 
      }
      let featureIsEnabled = this.sessionStorageService.laborFeatureIsEnabled();
      if (featureIsEnabled == null) {
        featureIsEnabled = await this.proxyService.get('labor/labormanagementlicense/get');
        this.sessionStorageService.setLaborFeatureIsEnabled(featureIsEnabled);
      }
      return featureIsEnabled; 
    } catch {
      this.sessionStorageService.setLaborFeatureIsEnabled(false);
      return false;
    }
  }
  
  getLoginObserver(): Subject<boolean> {
    return this.userIsLoggedIn$;
  }
}
