import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ContextService } from './services/context-service';
import { LoggerService } from './services/logger-service';
import { AuthenticationService } from './services/authentication-service';

@Injectable()
export class CanActivateGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private contextService: ContextService,
    private loggerService: LoggerService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    // Observable<boolean> |
    Promise<boolean> {
    return new Promise<any>(async (resolve, _reject) => {
      // environmentally illegal routes
      this.loggerService.logMessage(route.url.join('/'));
      // if (!environment.featureToggles.examplesArea) {
      //     if (route.url[0].path === 'examples') {
      //         this.loggerService.logMessage('AUTHGUARD - Environmentally illegal route encountered');
      //         this.router.navigate(['/']);
      //         //resolve('illegal route - redirecting');
      //         resolve(false);
      //     }
      // }
      // if (!environment.featureToggles.inventoryInspectorDashboard) {
      //     if (route.url[0].path === 'inventory' && route.url[1].path === 'dashboard') {
      //         this.loggerService.logMessage('AUTHGUARD - Environmentally illegal route encountered');
      //         this.router.navigate(['/inventory/inspector']);
      //         //resolve('illegal route - redirecting');
      //         resolve(false);
      //     }
      // }
      // already logged in
      if (this.contextService.loggedIn) {
        this.loggerService.logMessage('AUTHGUARD - Logged in');
        if (route.url.length > 0) {
          const resolved = this.checkRoutePermission(resolve, route);

          if (!resolved) {
            return;
          }
        }
        resolve(true);
      } else {
        // new tab
        // OR initial visit
        // OR reload
        this.loggerService.logMessage('AUTHGUARD - Not logged in');        
        // log in with cookies
        try {
          await this.authenticationService.loginWithCookies();
          this.loggerService.logMessage('AUTHGUARD - OK - COOKIES');
          const resolved = this.checkRoutePermission(resolve, route);
          if (!resolved) {
            return;
          }
          resolve(true);
        } catch (_err) {
          try {
            await this.authenticationService.loginWithToken();
            this.loggerService.logMessage('AUTHGUARD - OK - TOKEN');
            const resolved = this.checkRoutePermission(resolve, route);
            if (!resolved) {
              return;
            }
            resolve(true);
          } catch (_err_1) {
            this.loggerService.logMessage('AUTHGUARD - Not logged in - FINAL');
            this.router.navigate(['/login'], {
              queryParams: route.queryParams
            });
            // resolve('no valid auth method found');
            resolve(false);
          }
        }
      }
    });
  }

  private checkRoutePermission(resolve: (value: any) => void, route: ActivatedRouteSnapshot): boolean {
    const userIsAManagerOrCommandCenterOnly = this.contextService.isAManager() || this.contextService.isCommandCenterOnly();
    const userIsCurbsideOnly = this.contextService.isCurbsideOnly();

    const targetRoute = route.routeConfig && route.routeConfig.path || route.url[0].path;
 
    // Client Admin
    if (targetRoute !== 'home' && !this.contextService.isClientAdmin()) {
      if ('text-templates' == targetRoute) {
        this.router.navigate(['/home']);
        resolve(false);
        return false;
      }
    }

    // CC Only User
    if (targetRoute !== 'home' && this.contextService.isCommandCenterOnly()) {
      const ccOnlyPaths = ['events', 'stagedorders', 'text-templates'];
      if (ccOnlyPaths.some(r => r === targetRoute)) {
        this.router.navigate(['/home']);
        resolve(false);
        return false;
      }
    }

    // Not Manager User
    if (targetRoute !== 'home' && !userIsAManagerOrCommandCenterOnly) {
      this.router.navigate(['/home']);
      resolve(false);
      return false;
    }

    // Curveside Only User
    if (userIsCurbsideOnly) {
      const curbesidePaths = ['inventory', 'orderarchives', 'orderlist', 'orderdetail', 'communications', 'text-templates', 'chats'];
      const isInventory = route.routeConfig.component && route.routeConfig.component.name == 'InventoryInspectorComponent' || false;
      const isOrderDetail = route.routeConfig.component && route.routeConfig.component.name == 'OrderdetailComponent' || false;
      const isOrderList = route.routeConfig.component && route.routeConfig.component.name == 'OrderlistComponent' || false;
      if (isInventory || isOrderList || isOrderDetail || curbesidePaths.some(r => r === targetRoute)) {
        this.router.navigate(['/home']);
        resolve(false);
        return false;
      }
    }
    return true;
  }
}

