﻿import { Injectable, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from './logger-service';

@Injectable()
export class SidePanelService {

  constructor(
    private router: Router,
    private loggerService: LoggerService,
  ) {
  }

  title: string;
  isUsed: boolean;
  isVisible: boolean;
  template: TemplateRef<any> = null;
  onAnimationDoneDelay = 250;
  headerChangeDetector: ChangeDetectorRef;
  sidePanelChangeDetector: ChangeDetectorRef;
  sideAnimationState = 'out';
  showTitle = true;
  padding = 10;
  backgroundColor = 'gainsboro';
  onReload: Function = () => { };
  onAnimationDone: Function = () => { };
  onFilterAnimationDoneFnc: Function = () => { };
  updateViewComponents() {
    this.headerChangeDetector.detectChanges();
    this.sidePanelChangeDetector.detectChanges();
  }
  //
  use(template: TemplateRef<any>, isVisibleSetting?: boolean, onAnimationDoneCallback?: Function, title?: string,
    onReloadCallback?: Function, showTitle?: boolean, padding?: number, backgroundColor?: string) {
    this.isUsed = true;
    this.onAnimationDone = onAnimationDoneCallback ? onAnimationDoneCallback : () => { };
    this.sidePanelChangeDetector.markForCheck();
    this.title = title;
    this.showTitle = showTitle;
    this.padding = padding;
    this.backgroundColor = backgroundColor;
    this.setTemplate(template);
    this.onReload = onReloadCallback ? onReloadCallback : () => { };
    isVisibleSetting ? this.show() : this.hide();
  }
  clear() {
    this.onAnimationDone = () => { };
    this.onReload = () => { };
    this.isUsed = false;
    this.clearTemplate();
  }
  //
  setTemplate(template: TemplateRef<any>) {
    this.template = template;
    this.updateViewComponents();
  }
  setTitle(title: string) {
    this.title = title;
    this.updateViewComponents();
  }
  clearTemplate() {
    this.isVisible = false;
    this.sideAnimationState = 'out';
    this.template = null;
    this.updateViewComponents();
  }
  //
  // get sideAnimationState(): string {
  //    return this.isUsed && this.isVisible ? 'in' : 'out';
  // }
  //
  toggle() {
    this.isVisible ? this.hide() : this.show();
  }
  hide() {
    if (this.isUsed) {
      this.isVisible = false;
      this.sideAnimationState = 'out';
      this.updateViewComponents();
    }
  }
  show() {
    if (this.isUsed) {
      this.isVisible = true;
      this.sideAnimationState = 'in';
      this.updateViewComponents();
    }
  }
  //
  onSideAnimationDone(event: any) {
    // this.loggerService.logMessage(event);
    setTimeout(() => {
      this.onAnimationDone(this.isVisible);
      // event.toState == 'out' ? this.loggerService.logMessage('hidden') : console.log('visible');
    }, this.onAnimationDoneDelay);
  }

  onFilterAnimationDone(event: any) {
    // this.loggerService.logMessage(event);
    setTimeout(() => {
      this.onFilterAnimationDoneFnc(this.isVisible);
      // event.toState == 'out' ? this.loggerService.logMessage('hidden') : console.log('visible');
    }, this.onAnimationDoneDelay);
  }

  titleIsVisible(): boolean {
    return this.showTitle;
  }

  getPadding(): number {
    return this.padding;
  }

  getBackgroundColor(): string {
    return this.backgroundColor;
  }
}
