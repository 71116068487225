// /// <reference path="../../typings/index.d.ts" />
import { NgModule } from '@angular/core';
//import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppCoreCustomPreloader } from './core.preloader';
// import { CanActivateGuard } from './core.authguard';
// import { HomeComponent } from '../@features/home/home';
// import { LoginComponent } from '../@features/login/login';

const appRoutes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    // {
    //     path: '', component: HomeComponent, canActivate: [CanActivateGuard],
    //     //children: [
    //         //{ path: 'dashboard', component: HomeComponent, canActivate: [CanActivateGuard] }
    //     //]
    // },
    // { path: 'login', component: LoginComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot([], { preloadingStrategy: AppCoreCustomPreloader })
    ],
    providers: [
        AppCoreCustomPreloader
    ],
    exports: [
        RouterModule
    ]
})
export class AppCoreRoutingModule {}
