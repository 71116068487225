﻿import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NotificationService {

    private notificationObserverSrc = new Subject<any>();
    public notificationLsnr = this.notificationObserverSrc.asObservable();

    constructor() {

    }

    setNotificationMessage(message: any) {
        this.notificationObserverSrc.next(message);
    }

    showNotification(type: string, title: string, content: string) {
        this.setNotificationMessage({
            type: type,
            title: title,
            content: content
        });
    }
}