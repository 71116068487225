export enum OrderTypeEnum {
  PickUp = 1,
  Delivery,
  Kiosk
}

export enum OrderModalModeEnum {
  Cancel = 1,
  Complete,
  UpdatePromiseTime,
  CancelMultiple,
  CompleteMultiple,
  UpdatePromiseTimeMultiple
}

export type OrderModalMode = typeof OrderModalModeEnum.Cancel | typeof OrderModalModeEnum.Complete
  | typeof OrderModalModeEnum.UpdatePromiseTime | typeof OrderModalModeEnum.CancelMultiple
    | typeof OrderModalModeEnum.CompleteMultiple | typeof OrderModalModeEnum.UpdatePromiseTimeMultiple;
