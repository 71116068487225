import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCoreModule } from './@core/core.module';
import { AppComponent } from './app';
import { OAuthModule } from 'angular-oauth2-oidc';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppCoreModule,
    OAuthModule.forRoot()
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  providers: []
})

export class AppModule {
}
