import { Component, OnInit } from '@angular/core';

import { NavService } from '../../services/nav-service';
import { ContextService } from '../../services/context-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sk-nav',
  templateUrl: './nav.html',
  styleUrls: ['./nav.scss'],
})
export class NavComponent implements OnInit {
  showLabolMenuItem = false;
  laborMenuItemChecked = false;  
  userIsAManagerOrCommandCenterOnly = false;
  userIsAManager = false;
  userIsAClientAdmin = false;
  isCurbsideOnly = false;
  constructor(
    public nav: NavService,
    public contextService: ContextService,
  ) {
  }

  async ngOnInit() {
    this.showLabolMenuItem = await this.nav.checkLaborLicense();
    this.nav.menuButtonEmitter.subscribe(async (menuOpened: boolean) => {
      if (menuOpened) {
        this.showLabolMenuItem = await this.nav.checkLaborLicense();
      }
    });
    this.nav.getLoginObserver().subscribe((userIsLoggedIn: boolean) => {
      if(userIsLoggedIn) {
        this.userIsAManager = this.contextService.isAManager() && !this.contextService.isCommandCenterOnly();
        this.userIsAManagerOrCommandCenterOnly = this.contextService.isAManager() || this.contextService.isCommandCenterOnly();
        this.userIsAClientAdmin =  this.contextService.isClientAdmin();
        this.isCurbsideOnly = this.contextService.isCurbsideOnly();
      } else {
        this.userIsAManager = false;
        this.userIsAManagerOrCommandCenterOnly = false;
        this.userIsAClientAdmin = false;
        this.isCurbsideOnly = false;
      }
    });
  }

  showExamples() {
    return environment.featureToggles.examplesArea;
  }

  get clientName() {
    return this.contextService.userContext().clientName;
  }

  isClientAdmin() {
    return this.contextService.isClientAdmin();
  }

  isSysAdmin() {
    return this.contextService.isSysAdmin();
  }

  isCommandCenterOnlyUser(){
    return this.contextService.isCommandCenterOnly();
  }

  async onNavigate() {
    const url = await this.nav.getLaborUrl();
    window.open(url, "_blank");
  }
}
