﻿import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

import { ICustomDialog, ICustomDialogAction } from '../models/custom-dialog';

@Injectable({
  providedIn: 'root'
})
export class CustomDialogService {
  dialogSubject$ = new Subject<ICustomDialog>();
  dialogActionSubject$ = new Subject<ICustomDialogAction>();
  constructor() {
  }
}
