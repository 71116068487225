<div class="modal d-block">
    <div class="modal-dialog">
        <div class="modal-content" style="border-top-width: 0px !important; border-bottom-width: 0px !important;">

            <div class="modal-header">
                <h4 class="modal-title">Delivery Status History #{{orderSourceKey}}</h4>

                <button type="button" class="close" aria-label="Close" (click)="dismiss()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
              
              <div class="list-modal">

                <div class="list-modal-option" [ngClass]="{'list-modal-option-first': (items.length === 1 || isLastItem(item))}" *ngFor="let item of items">
                  <div class="order" #order>
                    <div class="order__ico">
                      <div class="order__ico__btn" (click)="toggleExpanded(item, order)">
                        <i *ngIf="!item.expanded" class="fa fa-angle-down" style="font-size: 24px;"></i>
                        <i *ngIf="item.expanded" class="fa fa-angle-up" style="font-size: 24px;"></i>
                      </div>
                    </div>
                    <div class="order__start">
                      <span class="order__start__time" [ngClass]="{'error': item.markAsDeliveryException}">{{getFormatedDate(item)}}</span>
                      <span class="order__start__status" [ngClass]="{'error': item.markAsDeliveryException}">{{item.newOrderDeliveryStatus}}</span>
                      <span class="order__start__user" [ngClass]="{'error': item.markAsDeliveryException}">{{item.createdByUserName}}</span>
                      <span *ngIf="item.expanded" class="order__start__desc__header" [ngClass]="{'error': item.markAsDeliveryException}">Action:</span>
                      <p *ngIf="item.expanded" class="order__start__desc__content" [ngClass]="{'error': item.markAsDeliveryException}">{{item.actionDesc}}</p>
                      <span *ngIf="showDeliveryProvider(item)" class="order__start__desc__header" [ngClass]="{'error': item.markAsDeliveryException}">Delivery Provider:</span>
                      <p *ngIf="showDeliveryProvider(item)" class="order__start__desc__content" [ngClass]="{'error': item.markAsDeliveryException}">{{item.deliveryProviderSourceKey}}</p>
                      <span *ngIf="item.expanded && item.markAsDeliveryException" class="order__start__desc__header error">Reason:</span>
                      <p *ngIf="item.expanded && item.markAsDeliveryException" class="order__start__desc__content error">{{item.newOrderDeliveryStatus}}</p>
                    </div>                    
                    <div class="order__end">
                      <div class="order__end__line" [ngClass]="{'order__end__line__border': showTopBorder(item)}" [ngStyle]="{'height': item.height}">
                      </div>
                      <div class="order__end__circle" [ngClass]="{'order__end__circle__fill': showFilledCircle(item)}">
                      </div>
                      <div class="order__end__line" [ngClass]="{'order__end__line__border': showBottomBorder(item)}" [ngStyle]="{'height': item.height}">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">

              <button *ngIf="deliveryStatusHistory.allowDeliveryRetry && !retrySent && !retrying" type="button" class="btn btn-secondary" (click)="retry()">
                <i class="fa fa-history">&nbsp;&nbsp;Retry</i>
              </button>

              <i *ngIf="retrying" class="fa fa-spinner fa-spin" style="font-size:24px"></i><span *ngIf="retrying">&nbsp;Retrying</span>

              <button type="button" class="btn btn-primary" (click)="accept()">Ok</button>
            </div>
        </div>
    </div>
</div>
