import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../@core/services/spinner-service';
import { OrderService } from '../../../@core/services/order-service';
import { Subject } from 'rxjs';

@Component({
  selector: 'sk-reserve-modal',
  styleUrls: ['./reserve-modal.component.scss'],
  templateUrl: './reserve-modal.component.html',
})
export class ReserveModalComponent implements OnInit, AfterViewInit {
  @Input() users = [];
  @Input() orderId = 0;
  @Input() storeId = 0;
  @Input() multipleMode = false;
  @Input() shoppersSelected = [];
  @Input() public dismissAction$ = new Subject();
  userReserve: string[] = [];
  userReserveNames: any[] = [];
  constructor(
    private modalService: NgbModal,
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.users.forEach((sho) => {
      sho.isChecked = this.shoppersSelected.some(s => s.id == sho.id);
    });
    this.userReserveNames = this.shoppersSelected.map(s => s);
    this.userReserve = this.shoppersSelected.map(s => s.id);
    this.cdr.detectChanges();
  }

  decline() {
    this.modalService.dismissAll();
    this.dismissAction$.next('canceled');
  }

  async accept() {
    if (!this.multipleMode) {
      this.spinnerService.styles.zindex = 99999;
      this.spinnerService.show('Reserving Orders');
      await this.orderService.reserveOrder(this.storeId, this.orderId, this.userReserve);
      this.orderService.reserveOrderSubject$.next(this.userReserveNames);
      this.spinnerService.hide();
    } else {
      this.orderService.reserveOrderSubject$.next(this.userReserve);
    }
    this.modalService.dismissAll();
    this.spinnerService.styles.zindex = 998;
  }

  dismiss() {
    this.modalService.dismissAll();
    this.dismissAction$.next('canceled');
  }

  addUserReserve(user: any) {
    if (this.shoppersSelected.filter(ss => ss.id === user.id).length > 0) {
      this.userReserve = this.userReserve.filter(u => u !== user.id);
      this.userReserveNames = this.userReserveNames.filter(u => u.id !== user.id);
    } else {
      if (this.userReserve.filter(ss => ss === user.id).length > 0){
        this.userReserve = this.userReserve.filter(u => u !== user.id);
        this.userReserveNames = this.userReserveNames.filter(u => u.id !== user.id);
      } else {
        this.userReserve.push(user.id);
        this.userReserveNames.push(user);
      }
    }
  }
}
