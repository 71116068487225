<div class="modal d-block">
    <div class="modal-dialog">
        <div class="modal-content" style="border-top-width: 0px !important; border-bottom-width: 0px !important;">

            <div class="modal-header">
                <h4 class="modal-title">Order Status History #{{orderSourceKey}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
              <div class="list-modal">

                <div class="list-modal-option" [ngClass]="{'list-modal-option-first': (filteredList.length === 1 || isLastItem(item))}" *ngFor="let item of filteredList">
                  <div class="order" #order>
                    <div class="order__start">
                      <span class="order__start__time">{{getFormatedDate(item)}}</span>
                      <span class="order__start__status">{{item.newStatusName}}</span>
                      <span class="order__start__user">{{item.userName}}</span>
                    </div>                    
                    <div class="order__end">
                      <div class="order__end__line" [ngClass]="{'order__end__line__border': showTopBorder(item)}" [ngStyle]="{'height': item.height}">
                      </div>
                      <div class="order__end__circle" [ngClass]="{'order__end__circle__fill': showFilledCircle(item)}">
                      </div>
                      <div class="order__end__line" [ngClass]="{'order__end__line__border': showBottomBorder(item)}" [ngStyle]="{'height': item.height}">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="accept()">Ok</button>
            </div>
        </div>
    </div>
</div>
