import { Component, OnInit, ElementRef, DoCheck } from '@angular/core';
import { SpinnerService } from '../../services/spinner-service';

@Component({
  selector: 'sk-spinner',
  templateUrl: './spinner.html',
  styleUrls: ['./spinner.scss'],
})
export class SpinnerComponent implements OnInit, DoCheck {

  constructor(
    public spinner: SpinnerService,
    private elementRef: ElementRef
  ) {
  }

  ngDoCheck(): void {
    const zindex = this.spinner.styles && this.spinner.styles.zindex || 998;
    this.elementRef.nativeElement.style.setProperty('--var-zindex', zindex);
  }

  ngOnInit() {
  }

}
