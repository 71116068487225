// /// <reference path="../../typings/index.d.ts" />
import { NgModule } from '@angular/core';
// import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateGuard } from '../@core/core.authguard';
import { HomeComponent } from '../@features/home/home';
import { LoginComponent } from '../@features/login/login';
import { OAuthComponent } from './oauth/oauth';

const routes: Routes = [

    // default route
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    // eager-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'oauthlogin', component: OAuthComponent },
    { path: 'home', component: HomeComponent, canActivate: [CanActivateGuard] },
    // lazy-loaded routes (preloaded)
    {
        path: 'inventory',
        loadChildren: './@inventory/inventory.module#InventoryModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    {
        path: 'orders',
        loadChildren: './@orders/orders.module#OrdersModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    {
        path: 'events',
        loadChildren: './@events/events.module#EventsModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    {
        path: 'communications',
        loadChildren: './@communications/communications.module#CommunicationsModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    {
        path: 'stagedorders',
        loadChildren: './@stagedorders/stagedorders.module#StagedOrdersModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    {
        path: 'communicationshub',
        loadChildren: './@communicationshub/communicationshub.module#CommunicationsHubModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    {
        path: 'orderarchives',
        loadChildren: './@ordersarchives/ordersarchives.module#OrdersArchivesModule',
        canActivate: [CanActivateGuard],
        data: { preload: true }
    },
    // catch-all route
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class FeaturesRoutingModule {}
