
<!--
OLD
<router-outlet></router-outlet>
<div class="footer">
    <span>&copy; 2018 ShopperKit All Rights Reserved</span>
</div>
    -->
    

<!--<div class="wrapper">
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        <span>&copy; 2018 ShopperKit All Rights Reserved</span>
    </div>
</div>-->

<div class="content" #content>
    <router-outlet></router-outlet>
</div>
<div class="footer" *ngIf="footerEnabledTemp">
    <span [innerHTML]="footerText"></span>
</div>
