import { Component, OnInit } from '@angular/core';
import { IdleListenerService } from '../../services/idle-listener-service';
import { idleListenerAnimatedModal } from '../animations';
//import { environment } from '../../../../environments/environment';
//import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
//import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'sk-idle-listener',
  templateUrl: './idleListener.html',
  styleUrls: ['./idleListener.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [idleListenerAnimatedModal]
})
export class IdleListenerComponent {
    constructor(
        public idleListener: IdleListenerService,
    ) {
    }
}
