<div class="modal d-block">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Reserve Order For</h4>
                <button type="button" class="close" aria-label="Close" (click)="dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="list-modal" *ngIf="users">
                    <div class="list-modal__option" *ngFor="let user of users">
                        <div class="list-modal__option__check">
                            <input type="checkbox" id="scales" name="scales" (click)="addUserReserve(user)" [(ngModel)]="user.isChecked">
                        </div>
                        <div class="list-modal__option__text">{{user.firstName}} {{user.lastName}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger"
                    (click)="decline()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="accept()">Ok</button>
            </div>
        </div>
    </div>
</div>
