﻿import { Injectable } from '@angular/core';
import { ContextInfo } from '../../@shared/models/context-info';

@Injectable()
export class SessionStorageService {
  clientId: number;
  userName: string;
  setStorageContext(userContext: ContextInfo) {
    this.clientId = userContext.clientId;
    this.userName = userContext.userName;
    console.log({ message: 'setStorageContext', clientId: this.clientId, userName: this.userName });
  }
  getEncodedKey(key: string, source?: string) {
    const encoded = `skCc:${this.clientId}:${this.userName}:${key}`;
    // console.log(encoded, source);
    return encoded;
  }
  set(key: string, obj: any, useEncodedKey: boolean = true) {
    // this.loggerService.logMessage('storing obj', key, obj);
    const encodedKey = useEncodedKey ? this.getEncodedKey(key, 'set') : key;
    window.sessionStorage.setItem(encodedKey, JSON.stringify(obj));
  }
  get(key: string, defaultValue?: any, useEncodedKey: boolean = true): any {
    const encodedKey = useEncodedKey ? this.getEncodedKey(key, 'get') : key;
    const stringified = window.sessionStorage.getItem(encodedKey);
    try {
      let rawValue = JSON.parse(stringified);
      // could do some more checks here, too
      if (Array.isArray(defaultValue) && !rawValue) {
        rawValue = [...defaultValue] || null;
      }
      return rawValue;
    } catch (err) {
      return defaultValue;
    }
  }
  remove(key: string, useEncodedKey: boolean = true): void {
    const encodedKey = useEncodedKey ? this.getEncodedKey(key, 'rm') : key;
    window.sessionStorage.removeItem(encodedKey);
  }

  getUserContext() {
    const stringified = window.sessionStorage.getItem('skCcUserContext');
    return JSON.parse(stringified);
  }
  setUserContext(user: ContextInfo) {
    const stringified = JSON.stringify(user);
    window.sessionStorage.setItem('skCcUserContext', stringified);
  }
  laborFeatureIsEnabled(): boolean | null {
    const stringified = window.sessionStorage.getItem('skCcLaborFeature');
    if (!stringified || stringified.length == 0) {
      return null;
    }
    return stringified.toLowerCase() == 'true';
  }
  setLaborFeatureIsEnabled(enabled: boolean) {
    window.sessionStorage.setItem('skCcLaborFeature', enabled ? 'true' : 'false');
  }
  clearUserContext() {
    window.sessionStorage.removeItem('skCcLaborFeature');
    window.sessionStorage.removeItem('skCcUserContext');
  }
}
