import { Component, OnInit, DoCheck, HostBinding, HostListener, ElementRef } from '@angular/core';
import { NavService } from '../../services/nav-service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'sk-content',
  templateUrl: './content.html',
  styleUrls: ['./content.scss'],
})
export class ContentComponent implements OnInit, DoCheck {

  @HostBinding('style.overflow-x') overflowX = 'hidden';
  @HostBinding('style.overflow-y') overflowY: string;

  footerEnabledTemp: boolean;

  constructor(
    public nav: NavService,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
    this.nav.contentScrollTop = '0px';
  }

  get footerText() {
    const version = `v${environment.version}`;
    const envCode = environment.isProd ? ` ` : ` (${environment.envCode}) `;
    const year = moment().format('YYYY');
    return `&copy; ${year} ShopperKit${envCode} ${version} - All Rights Reserved.`;
  }

  // this solves the issue with value changed after checked (this was a persistent one)
  ngDoCheck() {
    // various hacks

    // console.log('checking', this.sidePanel.sideAnimationState1);
    if (this.nav.footerEnabled !== this.footerEnabledTemp) {
      this.footerEnabledTemp = this.nav.footerEnabled;
    }

    if (this.nav.contentIsScrollable) {
      this.overflowY = 'auto';
    } else {
      this.overflowY = 'hidden';
    }
    this.elementRef.nativeElement.style.setProperty('--var-offsetHeight', `${this.nav.offsetTop}px`);
  }

  @HostListener('scroll', ['$event'])
  setScrollTop(event: any) {
    const scrollTop = event.target.scrollTop + 'px';
    this.nav.contentScrollTop = scrollTop;
  }
}
