<sk-filter-container *ngIf="filterContainerVisible" [@filterAnimatedPanel]="sideAnimationStateTemp"
  (@filterAnimatedContent.done)="sidePanel.onFilterAnimationDone($event)">
</sk-filter-container>
<sk-nav [@navAnimatedMenu]="nav.navAnimationState"></sk-nav>
<sk-header [@pageAnimatedHeader]="nav.pageAnimationState"
           (@pageAnimatedHeader.done)="nav.onPageAnimationDone($event)"
           (@pageAnimatedHeader.start)="nav.onPageAnimationStart($event)"
           [@navAnimatedHeader]="nav.navAnimationState"
           [@fullscreenAnimatedHeader]="screenFullService.screenfullAnimationState"
           ></sk-header>
<sk-full-screen-tab></sk-full-screen-tab>
<sk-side-panel [@sideAnimatedPanel]="sideAnimationStateTemp"
               (@sideAnimatedPanel.done)="sidePanel.onSideAnimationDone($event)"></sk-side-panel>
<sk-content [@pageAnimatedContent]="nav.pageAnimationState"
            [@sideAnimatedContent]="sideAnimationStateTemp"
            [@fullscreenAnimatedContent]="screenFullService.screenfullAnimationState"
            (click)="nav.hide()"></sk-content>
<sk-spinner></sk-spinner>
<sk-idle-listener></sk-idle-listener>
<sk-confirmation-dialog></sk-confirmation-dialog>
<sk-custom-modal-dialog></sk-custom-modal-dialog>
