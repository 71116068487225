// our root app component
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

import { AuthenticationService, AuthCodes } from '../../@core/services/authentication-service';
import { NavService } from '../../@core/services/nav-service';
import { TranslationService } from '../../@core/services/translation-service';
import { LoggerService } from '../../@core/services/logger-service';
import { environment } from '../../../environments/environment';
import { ContextService } from '../../@core/services/context-service';

@Component({
  templateUrl: 'login.html',
  styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  userName: string;
  password: string;
  message: string;
  errorMsgShow: boolean;
  errorMsg: string;
  loading = false;
  useOAuth: boolean;
  showJustLoginBtn: boolean;
  private routeSubs: Subscription;
  constructor(
    private nav: NavService,
    private authenticationService: AuthenticationService,
    public translationService: TranslationService,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private contextService: ContextService,
    private router: Router
  ) {
    this.nav.header(null);
    // dev debug env
    if (environment.overrideUsernameAndPassword) {
      this.userName = environment.username;
      this.password = environment.password;
    }
  }

  async ngOnInit() {
    this.errorMsgShow = false;
    this.useOAuth = false;
    this.showJustLoginBtn = false;
    this.routeSubs = this.activatedRoute.queryParams.subscribe(async params => {
      const isLoggedIn = this.contextService._loggedIn;
      if (!isLoggedIn) {
        const clientId = params['clientId'];
        const login = params['login'];
        if (clientId && !login) {
          this.useOAuth = true;
          this.contextService.setClientId(clientId);
          this.router.navigate(['/oauthlogin'], { queryParams : { clientId: +clientId}});
        } else if (clientId && login) {
          this.useOAuth = true;
          this.contextService.setClientId(+clientId);
          this.showJustLoginBtn = true;
        } else {
          this.clearDataAndResetToDefaults();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubs.unsubscribe();
    this.useOAuth = false;
    this.showJustLoginBtn = false;
  }

  forgotMyPasswordLink() {
    const base = environment.adminPortalUrl;
    const path = environment.adminPortalForgottenPasswordPath;
    return `${base}#/${path}`;
  }

  onLogin(event: any) {
    try {
      this.loading = true;
      this.authenticationService.login(this.userName, this.password).then(res => {
        this.loggerService.logData(res);
        this.errorMsgShow = false;
        this.errorMsg = null;
        this.nav.getLoginObserver().next(true);
        this.nav.gotoNavLink('/');
        this.loading = false;
      }).catch((err: string) => {
        this.handleAuthFail(err);
        this.loading = false;
      });
    } catch (e) {
      this.loggerService.logException(e);
    }
  }

  initOAuthLogin() {
    const clientId = this.contextService.getClientId();
    this.router.navigate(['/oauthlogin'], { queryParams : { clientId: clientId}});
  }

  private clearDataAndResetToDefaults() {
    this.errorMsgShow = false;
    this.errorMsg = '';
  }

  private handleAuthFail(err: string) {
    if (err === AuthCodes.InvalidAuthToken) {
      this.errorMsgShow = true;
      this.errorMsg = 'Invalid Authentication Token';
    }
    if (err === AuthCodes.InavlidUsernameOrPassword) {
      this.errorMsgShow = true;
      this.errorMsg = this.translationService.translate('Looks like your username or password are incorrect.');
    } else {
      this.errorMsgShow = false;
      this.errorMsg = null;
    }
  }
}
