﻿import { Injectable } from '@angular/core';
import { ProxyService } from './proxy-service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class TranslationService {
  private _engine: any;

  constructor(private proxyService: ProxyService) {
  }

  public init(): Promise<any> {
    const self = this;
    return new Promise<any>(function (resolve, reject) {
      let language: string = self.getFirstBrowserLanguage();
      if (language.indexOf('-') !== -1) {
        language = language.split('-')[0];
      }

      if (language.indexOf('_') !== -1) {
        language = language.split('_')[0];
      }

      const languageFilePath: string = 'assets/translations/cc-' + language + '.js';
      const ssid: string = 'id_' + language;
      if (sessionStorage) {
        if (sessionStorage.getItem(ssid)) {
          self._engine = JSON.parse(sessionStorage.getItem(ssid));
          return resolve(self._engine);
        } else {
          self.proxyService.getFullAnonymous(languageFilePath).then(res => {
            sessionStorage.setItem(ssid, JSON.stringify(res));
            self._engine = res;
            return resolve(self._engine);
          });
        }
      } else {
        self.proxyService.getFullAnonymous(languageFilePath).then(res => {
          sessionStorage.setItem(ssid, JSON.stringify(res));
          self._engine = res;
          return resolve(self._engine);
        });
      }
    });
  }

  public getFirstBrowserLanguage(): string {
    const nav: Navigator = window.navigator;
    const browserLanguagePropertyKeys: any = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
    let i = 0;
    let language = '';

    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return null;
  }

  public translate(key: string): string {
    return this._engine[key];
  }
}
