﻿
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '../@shared/shared.module';
import { FeaturesRoutingModule } from './features.routing';

// customer-facing features
import { LoginComponent } from './login/login';
import { HomeComponent } from './home/home';
import { OAuthComponent } from './oauth/oauth';

@NgModule({
  imports: [
    CommonModule,
    AppSharedModule,
    FeaturesRoutingModule,
  ],
  declarations: [
    LoginComponent,
    OAuthComponent,
    HomeComponent,
  ],
  providers: [
  ],
  exports: [
  ]
})
export class FeaturesModule { }

