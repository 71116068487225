import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

import { LoggerService } from './logger-service';
import { Constants } from '../constants';
import { ContextService } from './context-service';

@Injectable()
export class SocketService {
  constructor(
    private contextService: ContextService,
    private constants: Constants,
    private loggerService: LoggerService
  ) {
    this.connected = false;
  }

  public connected: boolean;
  public messages: Subject<string> = new Subject<string>();
  public stagedOrderMessages: Subject<string> = new Subject<string>();

  private ws: WebSocket;

  public initSocket(): Promise<any> {
    const _self = this;
    return new Promise<any>(function (resolve, reject) {
      _self.ws = new WebSocket(_self.constants.MessagingWebSocketUri + '0');

      // Connection opened
      _self.ws.addEventListener('open', function (event) {
        _self.connected = true;
        _self.setApp();
        resolve(true);
      });

      _self.ws.addEventListener('error', function (event) {
        if (!_self.connected) {
          reject(event);
        } else {
          _self.loggerService.logException(event);
        }
      });

      // Listen for messages
      _self.ws.addEventListener('message', function (event) {
        _self.loggerService.logMessage('Message from server:');
        _self.loggerService.logData(event.data);
        try {
          const obj = JSON.parse(event.data);
          if (obj.Type == _self.constants.MessageTypeStagedOrder) {
            _self.stagedOrderMessages.next(obj);
          } else {
            _self.messages.next(obj);
          }
        } catch (err) {
          _self.loggerService.logException({ message: 'Exception parsing json from socket message.', err });
        }
      });
    });
  }

  public disconnect() {
    if (this.connected && this.contextService.loggedIn) {
      this.ws.close();
    }
  }

  public setUser() {     
    if (this.connected && this.contextService.loggedIn) {
      this.ws.send('CURRENTUSER-' + this.contextService.userContext().userId + ';' + this.contextService.userContext().userName);
    }
  }

  public setStores(stores: string[]) {
    if (this.connected && this.contextService.loggedIn) {
      const storesFormatted = stores.join('~');
      this.ws.send('MULTISTORES-' + storesFormatted);
    }
  }

  public setApp() {
    if (this.connected) {
      this.ws.send('CURRENTAPP-SKCOMMANDCENTER');
    }
  }

  public send(message: any): void {
    this.ws.send(message);
  }

  public ping() {
    this.send('PING-' + this.contextService.userContext().userId);
  }
}
