<div class="modal d-block" *ngIf="isVisible | async">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ model.title }}</h4>
                <button type="button" class="close" aria-label="Close" (click)="dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ model.message }}
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="model.showOkButton" class="btn btn-danger"
                    (click)="decline()">{{ model.btnCancelText }}</button>
                <button type="button" class="btn btn-primary" (click)="accept()">{{ model.btnOkText }}</button>
            </div>
        </div>
    </div>
</div>