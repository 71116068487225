
      <svg class="shopping-cart"
            [@cartAnimation]="cartAnimationState" 
            (@cartAnimation.done)="onDoneAnimatingCart($event)"
            [style.height]="svgHeight"
            [style.width]="svgWidth"
        >
        <g class="shopping-cart-chassis" display="inline" 
                [attr.stroke]="color" 
                [attr.fill]="color" 
                [attr.stroke-width]="strokeWidth" 
            >
            <path
                class="shopping-cart-body" 
                [attr.transform]="defaultTransform"
                d="M25.2,34.5c-2.1,0-4.2,0-6.3,0c-1.2,0-2.4-0.9-2.7-2.1
		            c-0.2-0.8-0.4-1.5-0.6-2.3l-0.2-0.8c-0.7-3-1.4-6-2.1-9l-0.3-1.1c-0.4-1.9-0.8-3.7-1.3-5.6c0-0.1,0-0.1,0-0.1c0,0,0,0-0.1,0
		            c0,0,0,0,0,0c-0.5,0-1.1,0-1.6,0c-0.6,0-1.3,0-1.9,0c-1.7,0.1-2.9-0.7-3.4-2.3c-0.1-0.3,0.1-0.5,0.3-0.6c0.3-0.1,0.5,0.1,0.6,0.3
		            c0.4,1.1,1.1,1.6,2.4,1.6c0.7,0,1.3,0,2,0c0.5,0,1.1,0,1.6,0c0.8,0,1,0.5,1.1,0.9c0.4,1.9,0.8,3.7,1.3,5.6l0.3,1.1
		            c0.7,3,1.4,6,2.1,9l0.2,0.8c0.2,0.8,0.3,1.5,0.5,2.2c0.2,0.8,1,1.4,1.7,1.4c5.5,0,10.9,0,16.4,0c0.8,0,1.5-0.5,1.7-1.2
		            c0.6-1.8,1.3-3.5,1.9-5.3l3.1-8.8c0.1-0.3,0.4-0.4,0.6-0.3c0.3,0.1,0.4,0.4,0.3,0.6l-3.1,8.8c-0.6,1.8-1.2,3.5-1.9,5.3
		            c-0.4,1.1-1.5,1.9-2.7,1.9C31.9,34.5,28.6,34.5,25.2,34.5z"
            />
	        <path 
                class="shopping-cart-full-wheel-left" 
                [attr.transform]="defaultTransform"
                d="M20.2,35.5l0,1c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9
		            c0,0.7-0.3,1.4-0.8,1.9c-0.5,0.5-1.2,0.8-1.9,0.8c0,0,0,0,0,0c-1.6,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.6,2.7-2.6L20.2,35.5 M20.2,35.5
		            c-2,0-3.7,1.6-3.7,3.6c0,2.1,1.5,3.7,3.6,3.7c0,0,0,0,0.1,0c2.2,0,3.7-1.7,3.7-3.6C23.9,37.2,22.3,35.5,20.2,35.5
		            C20.3,35.5,20.2,35.5,20.2,35.5L20.2,35.5z"
            />
	        <path 
                class="shopping-cart-full-wheel-right" 
                [attr.transform]="defaultTransform"
                d="M34,35.5l0,0.8v0.2c1.5,0,2.7,1.2,2.7,2.7c0,0.7-0.3,1.3-0.8,1.9
		            c-0.5,0.5-1.2,0.8-1.9,0.8c-1.5,0-2.6-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7L34,35.5 M34,35.5C34,35.5,34,35.5,34,35.5
		            c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7c0,0,0,0,0,0c2,0,3.7-1.7,3.7-3.7C37.6,37.1,36,35.5,34,35.5L34,35.5z"
            />
        </g>
        <g class="shopping-cart-package" display="inline"
            [attr.stroke]="color" 
            [attr.fill]="color" 
            [attr.stroke-width]="strokeWidth" 
            >
		    <path 
                class="bag" 
                [attr.transform]="defaultTransform"
                d="M34.2,15.1h-14c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1v-14
			    C35.2,15.5,34.8,15.1,34.2,15.1z"
            />
	        <path 
                class="handle" 
                [attr.transform]="defaultTransform"
                d="M30.8,14.1c-0.3,0-0.5-0.2-0.5-0.5V11
		        c0-0.5-0.4-0.8-0.8-0.8h-4.4c-0.4,0-0.8,0.4-0.8,0.8v2.7c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V11c0-1,0.8-1.8,1.8-1.8h4.4
		        c1,0,1.8,0.8,1.8,1.8v2.7C31.2,13.9,31,14.1,30.8,14.1z"
            />
        </g>

      </svg>
    