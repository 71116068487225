import { Component, OnInit, DoCheck, ChangeDetectorRef, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { NavService } from '../../services/nav-service';
import { ContextService } from '../../services/context-service';
import { AuthenticationService } from '../../services/authentication-service';
import { SidePanelService } from '../../services/side-panel-service';
import { ScreenFullService } from '../../services/screenfull-service';
import { sideWidth } from '../animations/animations.sidePanel';
import { Router } from '@angular/router';

@Component({
  selector: 'sk-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, DoCheck {
  @ViewChild('mainHeader') private mainHeader: ElementRef;
  @ViewChild('subHeader') private subHeader: ElementRef;
  headerIsFullscreenAllowedTemp: boolean;
  showSubHeader = false;
  subHeaderTemplate: TemplateRef<any> = null;
  constructor(
    public nav: NavService,
    public sidePanel: SidePanelService,
    public auth: AuthenticationService,
    public contextService: ContextService,   
    private cdr: ChangeDetectorRef,
    public screenFullService: ScreenFullService,
    private elementRef: ElementRef,
    private router: Router
  ) {
    this.nav.headerChangeDetector = this.cdr;
    this.sidePanel.headerChangeDetector = this.cdr;
  }

  // this solves the issue with value changed after checked (this was a persistent one)
  ngDoCheck() {
    if (this.nav.headerIsFullscreenAllowed !== this.headerIsFullscreenAllowedTemp) {
      this.headerIsFullscreenAllowedTemp = this.nav.headerIsFullscreenAllowed;
    }
    this.showSubHeader = this.nav.subHeaderIsVisible();
    this.subHeaderTemplate = this.nav.getSubHeaderTemplate();
    this.elementRef.nativeElement.style.setProperty('--var--offsetWidth', '0px');
    if (this.showSubHeader && this.sidePanel.isVisible) {
      this.elementRef.nativeElement.style.setProperty('--var--offsetWidth', sideWidth());
    }
    this.nav.offsetTop = this.subHeader && this.subHeader.nativeElement.clientHeight || this.nav.subHeaderHeight || 0;
  }

  ngOnInit() {
  }

  logout() {
    this.auth.logoff();       
  }

  get userRoleBadgeIconClass() {
    const user = this.contextService.userContext();
    return user.getBadgeIconClass();
  }

  get userNameFormatted() {
    const user = this.contextService.userContext();
    return user.getFormattedUserName();
  }

  get clientName() {
    return this.contextService.userContext().clientName;
  }

  get userClaims() {
    const user = this.contextService.userContext();
    return user.claims;
  }

  get userName() {
    const user = this.contextService.userContext();
    return user.fullName;
  }

  navigateToHomeScreen() {
    const clientId = this.contextService.getClientId();
    if (this.contextService._loggedIn) {
      this.nav.gotoNavLink('/home');
    } else if (clientId && !this.contextService._loggedIn) {
      this.router.navigate(['/login'], { queryParams : { clientId: clientId, login: 1}});
    } else if (!this.contextService._loggedIn) {
      this.nav.gotoNavLink('/login');
    }
  }
}
