﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { forkJoin, of, Subject } from 'rxjs';

import { Constants} from 'src/app/@core/constants';
import { LoggerService } from './logger-service';
import { ContextService } from './context-service';
import { environment } from '../../../environments/environment';
import { IRequest } from '../models/request';
import { HttpMethodEnum } from '../enums/http-method.enum';

@Injectable()
export class ProxyService {
  constructor(
    private httpClient: HttpClient,
    private loggerService: LoggerService,
    private contextService: ContextService,
    private constants: Constants
  ) {
  }

  private createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Authorization', 'fulfiller ' + this.contextService.userContext().token);
    headers = headers.append('fulfiller-storeId', '0');
    headers = headers.append('fulfiller-clientId', this.contextService.userContext().clientId ?
      this.contextService.userContext().clientId.toString() : '0');
    return headers;
  }

  private createAuthorizationHeaderCore(headers: HttpHeaders, storeId?: number): HttpHeaders {
    headers = headers.append('Authorization', 'fulfiller ' + this.contextService.userContext().token);
    headers = headers.append('fulfiller-storeId', storeId ? storeId.toString() : '0');
    headers = headers.append('fulfiller-clientId', this.contextService.userContext().clientId ?
      this.contextService.userContext().clientId.toString() : '0');
    return headers;
  }

  private createAuthorizationHeaderWithStore(headers: HttpHeaders, storeId: number, clientId: number): HttpHeaders {
    headers = headers.append('Authorization', 'fulfiller ' + this.contextService.userContext().token);
    headers = headers.append('fulfiller-storeId', storeId.toString());
    headers = headers.append('fulfiller-clientId', clientId.toString());
    return headers;
  }

  private createBasicHeader(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json, application/javascript');
    return headers;
  }

  postMessagingWithStoreHeader(url: string, body: any, storeId?: number, clientId?: number) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      if (storeId && clientId) {
        headers = this.createAuthorizationHeaderWithStore(headers, storeId, clientId);
      } else if (storeId && !clientId) {
        headers = this.createAuthorizationHeaderCore(headers, storeId);
      } else if (!storeId && !clientId) {
        headers = this.createAuthorizationHeader(headers);
      }
      this.httpClient.post(this.constants.BaseMessagingServiceUri + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  postAnonymousCore(url: string, body: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      this.httpClient.post(environment.BaseApiCoreUrl + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  postCore(url: string, body: any, storeId?: number) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      headers = this.createAuthorizationHeaderCore(headers, storeId);
      this.httpClient.post(environment.BaseApiCoreUrl + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  postAnonymous(url: string, body: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      this.httpClient.post(environment.BaseApiUrl + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  post(url: string, body: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      headers = this.createAuthorizationHeader(headers);
      this.httpClient.post(environment.BaseApiUrl + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  getAnonymous(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      this.httpClient.get(environment.BaseApiUrl + url, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  getFullAnonymous(fullUrl: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      this.httpClient.get(fullUrl, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  get(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      headers = this.createAuthorizationHeader(headers);
      this.httpClient.get(environment.BaseApiUrl + url, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  getCore(url: string, storeId?: number, anonymousRequest: boolean = false): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      if (!anonymousRequest) {
        headers = this.createAuthorizationHeaderCore(headers, storeId);
      }     
      this.httpClient.get(environment.BaseApiCoreUrl + url, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  put(url: string, body: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      headers = this.createAuthorizationHeader(headers);
      this.httpClient.put(environment.BaseApiUrl + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  putCore(url: string, body: any, storeId?: number) {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      headers = this.createAuthorizationHeaderCore(headers, storeId);
      this.httpClient.put(environment.BaseApiCoreUrl + url, body, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  delete(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      headers = this.createAuthorizationHeader(headers);
      this.httpClient.delete(environment.BaseApiUrl + url, { headers: headers }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  deleteCore(url: string, body: any, storeId?: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      if (storeId) {
        const clientId = this.contextService.userContext().clientId;
        headers = this.createAuthorizationHeaderWithStore(headers, storeId, clientId);
      } else {
        headers = this.createAuthorizationHeader(headers);
      }
      this.httpClient.request('delete', environment.BaseApiCoreUrl + url, { headers: headers, body: body }).toPromise()
        .then(response => { resolve(response); })
        .catch(error => {
          this.loggerService.logMessage('in proxy service log error');
          this.loggerService.logException(error);
          reject(error);
        });
    });
  }

  async sendMultipleRequestsAtOnce(requests: IRequest[], coreRequest: boolean = false, storeId?: number): Promise<any[]> {
    const observableBatch: Observable<any>[] = [];
    const clientId = this.contextService.userContext().clientId;
    for (const request of requests) {
        let headers = new HttpHeaders();
        headers = this.createBasicHeader(headers);
        if (storeId) {
          headers = this.createAuthorizationHeaderWithStore(headers, storeId, clientId);
        } else if (request.storeId) {
          headers = this.createAuthorizationHeaderWithStore(headers, request.storeId, clientId);
        } else {
          headers = this.createAuthorizationHeader(headers);
        }

        const baseUrl = coreRequest ? environment.BaseApiCoreUrl : environment.BaseApiUrl;

        switch (request.type) {
            case HttpMethodEnum.GET:
                observableBatch.push(this.httpClient.get(baseUrl + request.endpoint, { headers })
                    .pipe(
                        tap(() => this.loggerService.logMessage(`GET Request sent to: '${request.endpoint}' was executed`)),
                        retry(4),
                        map((resp: Response) => resp),
                        catchError(err => {
                            this.loggerService.logMessage(`GET Request sent to: '${request.endpoint}' returned an error:`);
                            this.loggerService.logMessage(err.message);
                            return of(null);
                        })
                    ));
                break;
            case HttpMethodEnum.POST:
                observableBatch.push(this.httpClient.post(baseUrl + request.endpoint, request.data, { headers })
                    .pipe(
                        tap(() => this.loggerService.logMessage(`POST Request sent to: '${request.endpoint}' was executed`)),
                        retry(4),
                        map((resp: Response) => resp),
                        catchError(err => {
                            this.loggerService.logMessage(`POST Request sent to: '${request.endpoint}' returned an error:`);
                            this.loggerService.logMessage(err.message);
                            return of(null);
                        })
                    ));
                break;
            case HttpMethodEnum.PUT:
                observableBatch.push(this.httpClient.put(baseUrl + request.endpoint, request.data, { headers })
                    .pipe(
                        tap(() => this.loggerService.logMessage(`PUT Request sent to: '${request.endpoint}' was executed`)),
                        retry(4),
                        map((resp: Response) => resp),
                        catchError(err => {
                            this.loggerService.logMessage(`PUT Request sent to: '${request.endpoint}' returned an error:`);
                            this.loggerService.logMessage(err.message);
                            return of(null);
                        })
                    ));
                break;
            case HttpMethodEnum.DELETE:
                observableBatch.push(this.httpClient.delete(baseUrl + request.endpoint, { headers })
                    .pipe(
                        tap(() => this.loggerService.logMessage(`DELETE Request sent to: '${request.endpoint}' was executed`)),
                        retry(4),
                        map((resp: Response) => resp),
                        catchError(err => {
                            this.loggerService.logMessage(`DELETE Request sent to: '${request.endpoint}' returned an error:`);
                            this.loggerService.logMessage(err.message);
                            return of(null);
                        })
                    ));
                break;
        }
    }

    const multipleResponse = forkJoin(observableBatch);

    return await multipleResponse.toPromise();
  }

  async sendMultipleRequestsAtOnceWithNotification(requests: IRequest[], coreRequest: boolean = false, storeId?: number, notifier$: Subject<any> = null): Promise<any[]> {
    const promises = [];
    const clientId = this.contextService.userContext().clientId;
    if (!notifier$) {
      notifier$ = new Subject<any>();
    }
    for (const request of requests) {
      let headers = new HttpHeaders();
      headers = this.createBasicHeader(headers);
      if (storeId) {
        headers = this.createAuthorizationHeaderWithStore(headers, storeId, clientId);
      } else if (request.storeId) {
        headers = this.createAuthorizationHeaderWithStore(headers, request.storeId, clientId);
      } else {
        headers = this.createAuthorizationHeader(headers);
      }

      const baseUrl = coreRequest ? environment.BaseApiCoreUrl : environment.BaseApiUrl;
      let requestResponse = null;
      switch (request.type) {
        case HttpMethodEnum.GET: {
          requestResponse = this.notifyResponse(this.httpClient.get(baseUrl + request.endpoint, { headers }), request.id, notifier$);
          break;
        }
        case HttpMethodEnum.POST: {
          requestResponse = this.notifyResponse(this.httpClient.post(baseUrl + request.endpoint, request.data, { headers }), request.id, notifier$);
          break;
        }
        case HttpMethodEnum.PUT: {
          requestResponse = this.notifyResponse(this.httpClient.put(baseUrl + request.endpoint, request.data, { headers }), request.id, notifier$);
          break;
        }
        case HttpMethodEnum.DELETE: {
          requestResponse = this.notifyResponse(this.httpClient.delete(baseUrl + request.endpoint, { headers }), request.id, notifier$);
          break;
        }
      }
      promises.push(requestResponse);
    }
    return await Promise.all(promises)
  }

  private notifyResponse(request$: Observable<any>, requestId: string, notifier$: Subject<any>): Promise<any> {
    return request$.toPromise().then(response => {
      notifier$.next({
        id: requestId,
        isSuccess: true
      });
      return response;
    }).catch(error => {
      notifier$.next({
        id: requestId,
        isSuccess: false
      });
      return error;
    });
  }

}
