﻿
import {
    trigger,
    style,
    animate,
    transition,
    state,
    keyframes,
    group
} from '@angular/animations';

// nav menu
// breakage point
// user actually has to reload to reset this width
// if rotating a tablet crosses this boundary (1200)
export function sideWidth () {
    return '200px';
};

export const sideAnimationDuration = 250;

export const sideAnimatedContent = trigger('sideAnimatedContent', [
    state('out, void', style({
        right: 0,
    })),
    state('in', style({
        right: sideWidth(),
    })),
    transition('out => in', [
        animate(sideAnimationDuration)
    ])
]);

export const sideAnimatedPanel = trigger('sideAnimatedPanel', [
    state('out, void', style({
        transform: 'translateX(100%)',
        width: sideWidth(),
        opacity: 0,
    })),
    state('in', style({
        transform: 'translateX(0)',
        width: sideWidth (),
        opacity: 1,
    })),
    transition('out => in', [
        animate(sideAnimationDuration)
    ])
]);
