﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from './logger-service';
import { ProxyService } from './proxy-service';
import { ContextService } from './context-service';
import { SpinnerService } from './spinner-service';
import { TranslationService } from './translation-service';
import { SessionStorageService } from './session-storage-service';
import { NavService } from './nav-service';
import { IdleListenerService } from './idle-listener-service';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from './socket-service';
import { environment } from '../../../environments/environment';
import { ILoginSetting } from '../models/login';
import { OAuthService } from 'angular-oauth2-oidc';
import { TattletaleService } from 'src/app/@core/services/tattletale-service';
import { ToastrService } from 'ngx-toastr';
import { SignalRService } from './signalr-service';

export const AuthCodes = {
  InvalidSession: 'AuthCode::LoginFaliedInvalidSession',
  ProdModeAuthLoginNotEnabled: 'AuthCode::ProdModeAuthLoginNotEnabled',
  InvalidAuthToken: 'AuthCode::LoginFailedInvalidAuthToken',
  NoAuthToken: 'AuthCode::LoginFaliedNoAuthToken',
  InavlidUsernameOrPassword: 'AuthCode::LoginFaliedInavlidUsernameOrPassword',
  GetUserStoresFailure: 'AuthCode::GetUserStoresFailure',
  GetLoginSettingFailure: 'AuthCode::GetLoginSettingFailure',
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authTokenQueryParamName = 'auth';
  queryParams: any;

  lastAuthCheck: number = Date.now();
  authCheckTimer: any = null;  
  useNewMessagingService: boolean = true;
  constructor(
    private proxyService: ProxyService,
    private contextService: ContextService,
    private spinnerService: SpinnerService,
    private loggerService: LoggerService,
    private router: Router,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private socketService: SocketService,
    private sessionStorageService: SessionStorageService,
    private nav: NavService,    
    private idleListenerService: IdleListenerService,
    private oauthService: OAuthService,
    private tattle: TattletaleService,
    private toastrService: ToastrService,
    private signalRService: SignalRService,
  ) {
    // kept getting intermittent subscription error
    // until done this way instead of in the loginWithToken method below =>
    this.route.queryParamMap.subscribe(params => {
      // this.loggerService.logMessage('params');
      // this.loggerService.logData(params);
      this.queryParams = params;
    });
  }

  public init() {
  }

  setAuthCheckIntervalTimer() {
    const timeoutAuthCheckThreshold = (environment.idleAuthCheckThreshold || 5) * 1000; // milliseconds
    if (!this.authCheckTimer) {
      this.authCheckTimer = setInterval(() => {
        this.loggerService.logMessage('Checking Auth');
        if (this.contextService.isUserTokenExpired()) {
          this.logoff();
        }
      }, timeoutAuthCheckThreshold);
    }
  }

  clearAuthCheckIntervalTimer() {
    clearInterval(this.authCheckTimer);
    this.authCheckTimer = null;
  }

  public loginWithCookies(): Promise<any> {
    this.spinnerService.show();
    this.spinnerService.setMessage(this.translationService.translate('Logging in'));
    return new Promise<any>((resolve, reject) => {
      // check for logged in user
      // attempt to log in via cookies before hand
      const user = this.sessionStorageService.getUserContext();
      const tokenExpired = this.contextService.isUserTokenExpired();
      this.loggerService.logData({ user, message: `Token Expired?: ${tokenExpired}` });

      // maybe this wll make the load faster (so not trying to log in, etc for expired token)
      if (user && !tokenExpired) {
        // check for user token or other prop here
        // also check token expiry here
        this.loggerService.logMessage('page loaded & logged in via cookies');
        this.contextService.setUserFromStorage(user);

        this.proxyService.getCore('user/stores/' + user.userId).then((data: any) => {
          this.contextService.setUserStores(data.stores);
          this.loggerService.logMessage('logged in via api email and password');
          this.loggerService.logData(data);
          this.spinnerService.hide();   
          this.connect();       
          this.idleListenerService.startReset(this.logoff.bind(this));
          this.setAuthCheckIntervalTimer();
          this.nav.getLoginObserver().next(true);
          resolve(this.contextService.userContext());
        }).catch(err => {
          this.loggerService.logException(err);
          this.spinnerService.hide();
          this.idleListenerService.stopClear();
          this.nav.getLoginObserver().next(false);
          reject(AuthCodes.GetUserStoresFailure);
        });
      } else {
        this.spinnerService.hide();
        this.loggerService.logMessage('page loaded & NOT logged in via cookies');
        this.contextService.clear();
        this.idleListenerService.stopClear();
        this.clearAuthCheckIntervalTimer();
        this.nav.getLoginObserver().next(false);
        reject(AuthCodes.InvalidSession);
      }
    });
  }

  public login(email: string, password: string): Promise<any> {
    this.spinnerService.setMessage(this.translationService.translate('Logging in'));
    this.spinnerService.show();
    return new Promise<any>((resolve, reject) => {        
        const useNewMessagingService = this.useNewMessagingService;
        this.proxyService.postAnonymousCore('user/LoginWithEmail', { email, password, useNewMessagingService}).then((user: any) => {
        this.loggerService.logData(user);
        this.contextService.setUserContextFromLogin(user);
        this.proxyService.getCore('user/stores/' + user.userId).then((data: any) => {
          this.contextService.setUserStores(data.stores);
          this.loggerService.logMessage('logged in via api email and password');
          this.loggerService.logData(data);
          this.spinnerService.hide();   
          this.idleListenerService.startReset(this.logoff.bind(this));
          this.setAuthCheckIntervalTimer();
          this.connect();  
          this.nav.getLoginObserver().next(true);
          const userIsAManagerOrCommandCenterOnly = this.contextService.isAManager() || this.contextService.isCommandCenterOnly();
          if (!userIsAManagerOrCommandCenterOnly) {
            this.toastrService.info("Your current role doesn't have permission to access the command center, contact your administrator with further questions", 'Warning');
          } 
          resolve(this.contextService.userContext());
        }).catch(err => {
          this.loggerService.logException(err);
          this.spinnerService.hide();
          this.idleListenerService.stopClear();
          this.nav.getLoginObserver().next(false);
          reject(AuthCodes.GetUserStoresFailure);
        });
      }).catch(err => {
        this.loggerService.logException(err);
        this.spinnerService.hide();
        this.idleListenerService.stopClear();
        this.clearAuthCheckIntervalTimer();
        this.nav.getLoginObserver().next(false);
        reject(AuthCodes.InavlidUsernameOrPassword);
      });
    });
  }

  public loginWithToken(): Promise<any> {
    // will be changed to be hooked up to the backend
    // mocked for now
    return new Promise<any>((resolve, reject) => {
      if (this.queryParams) {
        const token = this.queryParams.get(this.authTokenQueryParamName);
        this.loggerService.logData(this.queryParams);
        this.loggerService.logMessage(token);
        if (!token) {
          this.loggerService.logMessage('not logged in with auth token because auth token not present in query params');
          this.idleListenerService.stopClear();
          this.clearAuthCheckIntervalTimer();
          reject(AuthCodes.NoAuthToken);
        } else {
          if (token === '12345') {
            // dev debug env
            if (environment.overrideUsernameAndPassword) {
              const email = environment.username;
              const password = environment.password;
              this.loggerService.logMessage('logging in with auth token');
              this.idleListenerService.startReset(this.logoff.bind(this));
              resolve(
                this.login(email, password)
              );
            } else {
              this.loggerService.logMessage('not logged in with auth token');
              this.idleListenerService.stopClear();
              this.clearAuthCheckIntervalTimer();
              this.nav.getLoginObserver().next(false);
              reject(AuthCodes.ProdModeAuthLoginNotEnabled);
            }
          } else {
            this.loggerService.logMessage('not logged in with auth token');
            this.idleListenerService.stopClear();
            this.nav.getLoginObserver().next(false);
            reject(AuthCodes.InvalidAuthToken);
          }
        }

      }
    });
  }

  public loginWithAccessToken(clientId: number, accessToken: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {     
      const useNewMessagingService = this.useNewMessagingService;       
      this.proxyService.postAnonymousCore('user/LoginWithAccessToken', { clientId, accessToken, useNewMessagingService }).then((user: any) => {
        this.loggerService.logData(user);
        this.contextService.setUserContextFromLogin(user);
        this.proxyService.getCore('user/stores/' + user.userId).then((data: any) => {
          this.contextService.setUserStores(data.stores);    
          this.connect();  
          this.idleListenerService.startReset(this.logoff.bind(this));
          this.setAuthCheckIntervalTimer();
          this.nav.getLoginObserver().next(true);
          resolve(this.contextService.userContext());
        }).catch(err => {
          this.tattle.log('AuthService::loginWithAccessToken.2 Error:', err);
          this.loggerService.logException(err);
          this.idleListenerService.stopClear();
          this.nav.getLoginObserver().next(false);
          reject(AuthCodes.GetUserStoresFailure);
        });
      }).catch(err => {
        this.tattle.log('AuthService::loginWithAccessToken.1 Error:', err);
        this.loggerService.logException(err);
        this.idleListenerService.stopClear();
        this.clearAuthCheckIntervalTimer();
        this.nav.getLoginObserver().next(false);
        reject(AuthCodes.InavlidUsernameOrPassword);
      });
    });
  }

  private connect() {
    if(!this.contextService.isSignalRMessagingEnabled()){
      this.socketService.initSocket().then(r => {
        console.log('websocket connected');
        this.socketService.setUser();
      });   
    } else {
      this.signalRService.connect().then((result) => {
        console.log('signalRService connected');
      }).catch((err) => {
        console.log('signalRService error on connect');
      });
    }      
  }

  private async diconnect() {
    if(!this.contextService.isSignalRMessagingEnabled()){
      this.socketService.disconnect();
    } else {
      await this.signalRService.disconnect();
      console.log('signalRService disconnected!!!!!');
    }      
  }

  public getLoginSettings(clientId: number): Promise<ILoginSetting> {
    return new Promise<any>((resolve, reject) => {
      this.proxyService.getCore(`client/${clientId}/loginsettings`, null, true).then((settingsResponse: any) => {
        const loginSettings = settingsResponse && settingsResponse.loginSettings || null;
        this.contextService.setLoginLoginSettings(loginSettings);
        resolve(loginSettings);
      }).catch(err => {
        this.loggerService.logException(err);
        reject(AuthCodes.GetLoginSettingFailure);
      });
    });
  }

  public logoff() {
    this.nav.hide();
    this.diconnect();
    const oauthLoginIsActive = this.contextService.oauthLoginIsActive();
    // const clientId = this.contextService.getClientId();
    this.contextService.clear();
    this.idleListenerService.stopClear();
    this.clearAuthCheckIntervalTimer();
    this.sessionStorageService.remove('orderInspector::filterSelectedDates'); 
    this.nav.getLoginObserver().next(false);
    if (oauthLoginIsActive) {
      // this.contextService.removeClientId();
      this.contextService.removeLoginSettings();
      this.contextService.removeOAuthLogin();
      this.oauthService.stopAutomaticRefresh();
      this.oauthService.logOut();
      // this.router.navigate(['/login'], { queryParams : { clientId: clientId, login: 1}});
    } else {
      this.router.navigate(['/login']);
    }
  }
}
