import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'src/app/@core/services/order-service';
import { SpinnerService } from 'src/app/@core/services/spinner-service';
import { ToastrService } from 'ngx-toastr';
import { OrderModalMode, OrderModalModeEnum } from '../../models/order-type.enum';
import * as moment from 'moment';
import { StoreOrderRow } from 'src/app/@features/@orders/orderInspector/orderInspector.storeOrderRow';
import { OrderStatusEnum } from 'src/app/@core/enums/order-status.enum';
import { Subject } from 'rxjs';
import { ContextService } from 'src/app/@core/services/context-service';

@Component({
  selector: 'sk-order-modal',
  styleUrls: ['./order-modal.component.scss'],
  templateUrl: './order-modal.component.html',
})
export class OrderModalComponent implements OnInit {
  @ViewChild('text') textArea: ElementRef<any>;
  @ViewChild('orderNumber') orderNumber: ElementRef<any>;
  @ViewChild('timepicker') timePicker: ElementRef<any>;
  @Input() public storeId: number;
  @Input() public orderId: number;
  @Input() public orderSourceKey: string;
  @Input() public promiseTime: string;
  @Input() public orderInspectorFactId: number;
  @Input() public mode: OrderModalMode = OrderModalModeEnum.Cancel;
  @Input() public orders: StoreOrderRow[];
  @Input() public dismissAction$ = new Subject();
  readonly MODE = OrderModalModeEnum;
  orderComment = '';
  orderInput: string;
  title: string;
  placeholderComment: string;
  inputPlaceholderComment: string;
  initialTime = { hour: 0, minute: 0};
  time = { hour: 0, minute: 0};
  model = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  minDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  timeValueIsCorrect = false;
  public errorMessage = '';
  constructor(
    private modalService: NgbModal,
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService,
    private contextService: ContextService) {
  }

  ngOnInit() {
    let title = 'Promise Time';
    let placeholderComment = 'Update Order Promise Time';
    switch(this.mode) {
      case OrderModalModeEnum.Cancel: {
        title = 'Cancel Order';
        placeholderComment = 'Add cancel comment here...';
        this.inputPlaceholderComment = 'Please type order number here'
        break;
      }
      case OrderModalModeEnum.CancelMultiple: {
        title = 'Cancel Orders';
        placeholderComment = 'Add cancel comment for orders here...';
        this.inputPlaceholderComment = 'Please type comma separated order numbers here'
        break;
      }
      case OrderModalModeEnum.Complete: {
        title = 'Complete Order';
        placeholderComment = 'Add completion comment here...';
        break;
      }
      case OrderModalModeEnum.CompleteMultiple: {
        title = 'Complete Orders';
        placeholderComment = 'Add completion comment for orders here...';
        break;
      }
      case OrderModalModeEnum.UpdatePromiseTime: {
        title = 'Promise Time';
        placeholderComment = 'Update Order Promise Time';
        break;
      }
      case OrderModalModeEnum.UpdatePromiseTimeMultiple: {
        title = 'Promise Time';
        placeholderComment = 'Update Orders Promise Time';
        break;
      }
    }
    this.title = title;
    this.placeholderComment = placeholderComment;
    if (this.mode !== OrderModalModeEnum.UpdatePromiseTime && this.mode !== OrderModalModeEnum.UpdatePromiseTimeMultiple) {
      if (this.mode === OrderModalModeEnum.Cancel || this.mode === OrderModalModeEnum.CancelMultiple) {
        setTimeout(() => {
          this.orderNumber.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          this.textArea.nativeElement.focus();
        }, 100);
      }      
    } else {
      if (this.mode == OrderModalModeEnum.UpdatePromiseTime) {
        this.setInitAndMinDate();
        const promiseTime = moment(this.promiseTime.replace('T', ' ') , 'YYYY-MM-DD HH:mm:ss');
        if (promiseTime.isBefore(moment())) {
          this.setTime(new Date());
        } else {
          this.setTime(promiseTime.toDate());
        }
      } else {
        this.setTime(new Date());
      }
      setTimeout(() => {
        this.checkDateTimeValue(this.model, this.time);
      }, 300);
    }
  }

  private setTime(date: Date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes < 30) {
      minutes = 30;
    } else if (minutes > 30) {
      minutes = 0;
      hours += 1;
    }
    this.time.hour = hours;
    this.time.minute = minutes;
    this.initialTime.hour = hours;
    this.initialTime.minute = minutes;
    this.timeValueIsCorrect = true;
  }

  private setInitAndMinDate() {
    const date = moment(this.promiseTime).toDate();
    this.model = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    const currentDate = new Date();
    this.minDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
  }

  decline() {
    this.modalService.dismissAll();
    this.dismissAction$.next('canceled');
  }

  async accept() {
    try {
      if (this.mode === OrderModalModeEnum.Cancel) {
        if (this.orderInput !== this.orderSourceKey) {
          this.toastrService.error(`Order number '${this.orderInput}' does not match the selected order ${this.orderSourceKey}`, 'Error', {
            positionClass: 'toast-top-right'
          });
          return;
        }
        this.spinnerService.styles.zindex = 999999;
        this.spinnerService.show('Cancelling Order');
        await this.orderService.cancelOrder(this.storeId, this.orderId, this.orderComment);
        this.dismissAction$.next('canceled');
        this.spinnerService.styles.zindex = 998;
      } else if (this.mode === OrderModalModeEnum.Complete) {
        this.spinnerService.styles.zindex = 999999;
        this.spinnerService.show('Completing Order');
        await this.orderService.updateOrderStatus(this.storeId, this.orderId, OrderStatusEnum.Done, this.orderComment);
        this.dismissAction$.next('canceled');
        this.spinnerService.styles.zindex = 998;
      } else if (this.mode === OrderModalModeEnum.UpdatePromiseTime) {
        let minTimeIntervalInMinutesForPromiseTimeUpdate = 120;
        const storeIndex = this.contextService._contextInfo.stores.findIndex(s => s.storeId == this.storeId);
        if (storeIndex != -1) {
          minTimeIntervalInMinutesForPromiseTimeUpdate = this.contextService._contextInfo.stores[storeIndex].minTimeIntervalInMinutesForPromiseTimeUpdate || 120;
        }
        const newDateStr = `${this.model.year}-${this.model.month}-${this.model.day} ${this.time.hour}:${this.time.minute}`;
        const newPromiseTime = moment(newDateStr, 'YYYY-MM-DD HH:mm');     
        const minAllowedTime = moment().add(minTimeIntervalInMinutesForPromiseTimeUpdate, 'minutes'); 
        if (newPromiseTime.isBefore(minAllowedTime)) {
          this.toastrService.error(this.getErrorMessage(minAllowedTime, minTimeIntervalInMinutesForPromiseTimeUpdate));
          this.setInitAndMinDate();
          return;
        }
        const tmpTime = moment(this.promiseTime);
        tmpTime.set({
          year: this.model.year,
          month: this.model.month - 1,
          date: this.model.day,
          hour: this.time.hour,
          minute: this.time.minute
        });
        this.spinnerService.styles.zindex = 999999;
        this.spinnerService.show('Updating Order Promise Time');
        const response = await this.orderService.updateOrderPromiseTime(this.storeId, this.orderId, this.orderInspectorFactId,
          tmpTime.format('YYYY-MM-DDTHH:mm:ssZ'));
        if (response && response.errors && response.errors.length > 0) {
          this.toastrService.error(response.responseMessage);
        } else {
          this.orderService.orderPromiseTimeSubject$.next(tmpTime.format('YYYY-MM-DDTHH:mm:ssZ'));
          this.dismissAction$.next('canceled');
        }
        this.spinnerService.styles.zindex = 998;
      } else if (this.mode === OrderModalModeEnum.UpdatePromiseTimeMultiple) {
        let minTimeIntervalInMinutesForPromiseTimeUpdate = 120;
        const storeIndex = this.contextService._contextInfo.stores.findIndex(s => s.storeId == this.storeId);
        if (storeIndex != -1) {
          minTimeIntervalInMinutesForPromiseTimeUpdate = this.contextService._contextInfo.stores[storeIndex].minTimeIntervalInMinutesForPromiseTimeUpdate || 120;
        }
        const newDateStr = `${this.model.year}-${(this.model.month < 10 ? '0' + this.model.month : this.model.month)}-${this.model.day < 10 ? '0' + this.model.day : this.model.day} ${this.time.hour < 10 ? '0' + this.time.hour : this.time.hour}:${this.time.minute < 10 ? '0' + this.time.minute : this.time.minute}:00`;
        const newPromiseTime = moment(newDateStr, 'YYYY-MM-DD HH:mm:ss');
        const minAllowedTime = moment().add(minTimeIntervalInMinutesForPromiseTimeUpdate, 'minutes'); 
        if (newPromiseTime.isBefore(minAllowedTime)) {
          this.toastrService.error(this.getErrorMessage(minAllowedTime, minTimeIntervalInMinutesForPromiseTimeUpdate));
          return;
        }
        const tmpTime = newPromiseTime;
        tmpTime.set({
          year: this.model.year,
          month: this.model.month - 1,
          date: this.model.day,
          hour: this.time.hour,
          minute: this.time.minute
        });
        this.orderService.orderPromiseTimeSubject$.next(tmpTime.format('YYYY-MM-DDTHH:mm:ssZ'));
      } else if (this.mode === OrderModalModeEnum.CancelMultiple) {
        const orderSourceKeys = [];
        this.orderInput.split(',').filter(o => o.trim().length > 0).map(id => id.trim().toLowerCase()).forEach(key => {
          if (!orderSourceKeys.includes(key)) {
            orderSourceKeys.push(key); 
          } 
        });
        const incorrectOrderNumbers = [];
        orderSourceKeys.forEach(orderNumber => {
          if (this.orders.every(o => o.orderSourceKey.toLowerCase() != orderNumber.toLowerCase())) {
            incorrectOrderNumbers.push(orderNumber);
          }
        });
        if (incorrectOrderNumbers.length > 1) {
          this.toastrService.error(`Order numbers '${incorrectOrderNumbers.join(',')}' are incorrect. They do not match the selected orders.`, 'Error', {
            positionClass: 'toast-top-right'
          });
          return;
        } else if (incorrectOrderNumbers.length == 1) {
          this.toastrService.error(`Order number '${incorrectOrderNumbers[0]}' is incorrect. It does not match any of the selected orders.`, 'Error', {
            positionClass: 'toast-top-right'
          });
          return;
        } else if (orderSourceKeys.length !== this.orders.length) {
          this.toastrService.error(`You have to type all the order numbers of the selected orders`, 'Error', {
            positionClass: 'toast-top-right'
          });
          return;
        }
        this.orderService.cancelOrdersSubject$.next({
          cancelMessage: this.orderComment,
          cancelOrder: this.orders
        });
      } else if (this.mode === OrderModalModeEnum.CompleteMultiple) {
        this.orderService.completeOrdersSubject$.next({
          completeMessage: this.orderComment
        });
      } 
      this.spinnerService.hide();
      this.modalService.dismissAll();
    } catch {
      this.spinnerService.styles.zindex = 998;
      this.spinnerService.hide();
      this.modalService.dismissAll();
      const errMsg = (this.mode === OrderModalModeEnum.Cancel || this.mode === OrderModalModeEnum.CancelMultiple) ? 'An error was received cancelling order(s)' :
        (this.mode === OrderModalModeEnum.Complete || this.mode === OrderModalModeEnum.CompleteMultiple) ? 'An error was received completing order(s)' :
          'An error was received updating order promise time';
      this.toastrService.error(errMsg, 'Error', {
        positionClass: 'toast-top-right'
      });
    }
  }

  dismiss() {
    this.modalService.dismissAll();
    this.dismissAction$.next('canceled');
  }

  dataIsNotCorrect(): boolean {
    if (this.mode !== OrderModalModeEnum.UpdatePromiseTime && this.mode !== OrderModalModeEnum.UpdatePromiseTimeMultiple) {
      return !this.orderComment || ((this.mode === OrderModalModeEnum.Cancel || this.mode === OrderModalModeEnum.CancelMultiple) ? !this.orderInput : false);
    } else {
      return !this.time || !this.timeValueIsCorrect;
    }
  }

  onTimeChange($event: any) {
    this.checkDateTimeValue(this.model, $event);
  }

  onDateChange($event: any) {
    this.checkDateTimeValue($event, this.time);
  }

  private checkDateTimeValue(date: any, time: any) {
    let minTimeIntervalInMinutesForPromiseTimeUpdate = 120;
    const storeIndex = this.contextService._contextInfo.stores.findIndex(s => s.storeId == this.storeId);
    if (storeIndex != -1) {
      minTimeIntervalInMinutesForPromiseTimeUpdate = this.contextService._contextInfo.stores[storeIndex].minTimeIntervalInMinutesForPromiseTimeUpdate || 120;
    }
    const minAllowedTime = moment().add(minTimeIntervalInMinutesForPromiseTimeUpdate, 'minutes'); 
    const newDateStr = `${date.year}-${(date.month < 10 ? '0' + date.month : date.month)}-${date.day < 10 ? '0' + date.day : date.day} ${time.hour < 10 ? '0' + time.hour : time.hour}:${time.minute < 10 ? '0' + time.minute : time.minute}:00`;
    const newPromiseTime = moment(newDateStr, 'YYYY-MM-DD HH:mm:ss');
    this.timeValueIsCorrect = newPromiseTime.isSameOrAfter(minAllowedTime);
    this.errorMessage = this.getErrorMessage(minAllowedTime, minTimeIntervalInMinutesForPromiseTimeUpdate);
  }

  private getErrorMessage(minDate: any, minTimeIntervalInMinutesForPromiseTimeUpdate: number): string {
    return `Promise Time can only be set to min ${(Math.round((minTimeIntervalInMinutesForPromiseTimeUpdate / 60 + Number.EPSILON) * 100) / 100)} hour(s) past current time, the new value should be equal to or later than '${minDate.format('L LT')}'`;
  }
}
