import { UserStore } from './user-store';

export class ContextInfo {
  public userName: string;
  // public email: string;
  public token: string;
  public loginTime: Date;
  public tokenExpires: Date;
  // public displayName: string;
  public fullName: string;
  // public firstName: string;
  // public lastName: string;
  public userId: string;
  public claims: string[];
  // public role: string;
  public clientId: number;
  public stores: UserStore[];
  public clientName: string;
  public isSuccess: boolean;
  public curbsideOnly: boolean;
  public signalRMessagingEnabled: boolean;

  mapFromStorage(storedUserObj: any) {
    Object.assign(this, storedUserObj);
  }

  constructor() {
  }

  mapFromLoginResponse(userObj: any) {

    const { claims, userName, expiresAt, token, userId, userFullName, clientID, email, firstName, lastName,
      clientName, isSuccess, curbsideOnly, signalRMessagingEnabled } = userObj;
    this.claims = claims;
    this.userName = userName;
    this.tokenExpires = expiresAt;
    this.token = token;
    this.userId = userId;
    this.fullName = userFullName;
    this.clientId = clientID;
    this.clientName = clientName;
    this.loginTime = new Date();
    this.isSuccess = isSuccess;
    this.curbsideOnly = curbsideOnly;
    this.signalRMessagingEnabled = signalRMessagingEnabled
    // this.email = email;
    // this.firstName = firstName;
    // this.role = role;
    // this.lastName = lastName;
  }

  setUserStores(stores: UserStore[]) {
    this.stores = stores;
  }

  getBadgeIconClass() {
    let result = null;
    if (this.claims.indexOf('systemAdmin') > -1) {
      result = 'fa fa-fw fa-user-secret';
    } else if (this.claims.indexOf('clientAdmin') > -1) {
      result = 'fa fa-fw fa-shield';
    } else if (this.claims.indexOf('storeManager') > -1) {
      result = 'fa fa-fw fa-user';
    }
    return result;
  }

  getFormattedUserName() {
    if (this.fullName) {
      const parts = this.fullName.split(' ');
      const result = parts[0].substr(0, 1) + parts[1].substr(0, 1);
      return result.toUpperCase();
    }
  }

}
