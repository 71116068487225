<div class="modal d-block">
    <div class="modal-dialog" [ngClass]="{'modal-dialog-centered': centered}">
        <div class="modal-content" style="border-top-width: 0px !important; border-bottom-width: 0px !important;">
            <div class="modal-header" [ngClass]="{'modal-header-danger': type == 'danger'}">
                <h5 class="modal-title">{{title}}</h5>
            </div>
            <div class="modal-body">
              <ngb-progressbar [type]="type" [striped]="!isCompleted" [animated]="!isCompleted" textType="white" [value]="value" [showValue]="false">{{progressMessage}}</ngb-progressbar>
              <ng-template [ngTemplateOutlet]="template"></ng-template>
            </div>
            <div *ngIf="showOkButton" class="modal-footer">
              <button type="button" class="btn" [ngClass]="{'btn-primary': type == 'success', 'btn-info': type == 'info', 'btn-warning': type == 'warning', 'btn-danger': type == 'danger'}"
                (click)="accept()">{{okTextButton}}</button>
            </div>
        </div>
    </div>
</div>
