import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { ContextService } from './services/context-service';

export interface IOrderStatus {
  orderStatusId: number;
  name: string;
  description: string;
}

export interface IOrderItemStatus {
  orderItemStatusId: number;
  name: string;
  description: string;
}

export interface IOrderType {
  orderTypeId: number;
  name: string;
  description: string;
}

export interface IOrderItemType {
  orderItemTypeId: number;
  name: string;
}

export interface IHistoricFilterDate {
  label: string;
  startDate: moment.Moment;
  dateCode: string;
  dayOffset?: number;
}

export interface IEventType {
  eventTypeId: number;
  label: string;
}

export interface IOptionString {
  optionId: number;
  name: string;
}

export interface IOptionNumber {
  optionId: number;
  value: number;
}

@Injectable()
export class Constants {

  public constructor(
    private contextService: ContextService
  ) {

    }

  public get BaseApiUri(): string { return environment.BaseApiUrl; }
  public get BaseMessagingServiceUri(): string { 
    if(this.contextService.isSignalRMessagingEnabled()){
      return environment.BaseMessagingServiceUriSignalr;
    }
    return environment.BaseMessagingServiceUri; 
  }
  public get MessagingWebSocketUri(): string { return environment.MessagingWebSocketUri; }
  public get MessageTypeCustomerMessaging(): number { return 1; }
  public get MessageTypeStoreNotification(): number { return 2; }
  public get MessageTypeOrderUpdate(): number { return 3; }
  public get MessageTypeDismissNotification(): number { return 4; }
  public get MessageTypeMessageReceived(): number { return 5; }
  public get MessageTypeEvent(): number { return 7; }
  public get MessageTypeStagedOrder(): number { return 8; }
  public get MessageTypeSmsUpdate(): number { return 9; }

  public get US_States() {
    const states = [
      { state: 'Alabama', postCode: 'AL' }, { state: 'Alaska', postCode: 'AK' },
      { state: 'Arizona', postCode: 'AZ' }, { state: 'Arkansas', postCode: 'AR' }, { state: 'California', postCode: 'CA' },
      { state: 'Colorado', postCode: 'CO' }, { state: 'Connecticut', postCode: 'CT' }, { state: 'Delaware', postCode: 'DE' },
      { state: 'District Of Columbia', postCode: 'DC' }, { state: 'Florida', postCode: 'FL' }, { state: 'Georgia', postCode: 'GA' },
      { state: 'Hawaii', postCode: 'HI' }, { state: 'Idaho', postCode: 'ID' }, { state: 'Illinois', postCode: 'IL' },
      { state: 'Indiana', postCode: 'IN' }, { state: 'Iowa', postCode: 'IA' }, { state: 'Kansas', postCode: 'KS' },
      { state: 'Kentucky', postCode: 'KY' }, { state: 'Louisiana', postCode: 'LA' },
      { state: 'Maine', postCode: 'ME' }, { state: 'Maryland', postCode: 'MD' }, { state: 'Massachusetts', postCode: 'MA' },
      { state: 'Michigan', postCode: 'MI' }, { state: 'Minnesota', postCode: 'MN' },
      { state: 'Mississippi', postCode: 'MS' }, { state: 'Missouri', postCode: 'MO' }, { state: 'Montana', postCode: 'MT' },
      { state: 'Nebraska', postCode: 'NE' }, { state: 'Nevada', postCode: 'NV' },
      { state: 'New Hampshire', postCode: 'NH' }, { state: 'New Jersey', postCode: 'NJ' }, { state: 'New Mexico', postCode: 'NM' },
      { state: 'New York', postCode: 'NY' }, { state: 'North Carolina', postCode: 'NC' }, { state: 'North Dakota', postCode: 'ND' },
      { state: 'Ohio', postCode: 'OH' }, { state: 'Oklahoma', postCode: 'OK' }, { state: 'Oregon', postCode: 'OR' },
      { state: 'Pennsylvania', postCode: 'PA' }, { state: 'Rhode Island', postCode: 'RI' }, { state: 'South Carolina', postCode: 'SC' },
      { state: 'South Dakota', postCode: 'SD' }, { state: 'Tennessee', postCode: 'TN' }, { state: 'Texas', postCode: 'TX' },
      { state: 'Utah', postCode: 'UT' }, { state: 'Vermont', postCode: 'VT' }, { state: 'Virginia', postCode: 'VA' },
      { state: 'Washington', postCode: 'WA' }, { state: 'West Virginia', postCode: 'WV' }, { state: 'Wisconsin', postCode: 'WI' },
      { state: 'Wyoming', postCode: 'WY' }
    ];

    return states;
  }
  //
  public get orderStatusDefinitions(): IOrderStatus[] {
    const statuses = [
      {
        orderStatusId: 1,
        name: 'New',
        description: 'New order with no activity.'
      },
      {
        orderStatusId: 1.2,
        name: 'Prep Started',
        description: 'Prep items started'
      },
      {
        orderStatusId: 1.4,
        name: 'Hub In Progress',
        description: 'Order is in progress at hub store.'
      },
      {
        orderStatusId: 1.41,
        name: 'Hub Picked',
        description: 'Order has been picked at hub store.'
      },
      {
        orderStatusId: 1.42,
        name: 'Hub Staged',
        description: 'Order has been Staged at hub store.'
      },
      {
        orderStatusId: 1.49,
        name: 'Hub Clear For Pos',
        description: 'Order is cleared for POS at hub store.'
      },
      {
        orderStatusId: 1.5,
        name: 'Hub Pos In Progress',
        description: 'Order has initiated POS at hub store.'
      },
      {
        orderStatusId: 1.51,
        name: 'Hub Pos Request Received',
        description: 'Order has received POS request at hub store.'
      },
      {
        orderStatusId: 1.52,
        name: 'Hub Pos Request Sent',
        description: 'Order has sent POS request at hub store.'
      },
      {
        orderStatusId: 1.6,
        name: 'Hub Ready For Loading',
        description: 'Order is ready for loading at hub store.'
      },
      {
        orderStatusId: 1.61,
        name: 'Hub Loading',
        description: 'Order is being loaded at hub store.'
      },
      {
        orderStatusId: 1.62,
        name: 'Hub Ready For Shipping',
        description: 'Order is ready for shipping at hub store.'
      },
      {
        orderStatusId: 1.7,
        name: 'Hub Shipped',
        description: 'Order is shipped at hub store.'
      },
      {
        orderStatusId: 1.8,
        name: 'Spoke Receiving',
        description: 'Order is being received at spoke store.'
      },
      {
        orderStatusId: 1.81,
        name: 'Spoke Received',
        description: 'Order has been received at spoke store.'
      },
      {
        orderStatusId: 2,
        name: 'In Progress',
        description: 'Pickable items are being picked.'
      },
      {
        orderStatusId: 3,
        name: 'Picked',
        description: 'All pickable items have been picked.'
      },
      {
        orderStatusId: 4,
        name: 'Staged',
        description: 'All pickable items have been staged.'
      },
      {
        orderStatusId: 4.9,
        name: 'Clear For POS',
        description: 'Order ready to initiate POS'
      },
      {
        orderStatusId: 5,
        name: 'POS In Progress',
        description: 'POS in-file is being generated and/or processed.'
      },
      // these depend on the customer (https://velocitychain.atlassian.net/wiki/spaces/SHOP/pages/380731393/Manually+Test+POS+in+UAT)
      {
        orderStatusId: 6,
        name: 'POS Request Received',
        description: 'POS payment gateway is opened.'
      },
      {
        orderStatusId: 7,
        name: 'POS Response Sent',
        description: 'POS payment gateway is opened.'
      },
      //
      {
        orderStatusId: 8,
        name: 'Ready for Carryout',
        description: 'Order has been settled at POS and is ready for carryout or delivery.'
      },
      {
        orderStatusId: 9,
        name: 'Loaded',
        description: 'Order has been loaded in the customer\'s vehicle or the delivery vehicle.'
      },
      {
        orderStatusId: 10,
        name: 'Cancelled',
        description: 'Order was Cancelled, either by the host system or through ShopperKit.'
      },
      {
        orderStatusId: 11,
        name: 'Delivery In Progress',
        description: 'Order is being delivered to the customer.'
      },
      {
        orderStatusId: 12,
        name: 'Done',
        description: 'Order is Completed and has been fulfilled.'
      }
    ];
    return statuses;
  }
  public orderStatusDefinition(orderStatusId: number): IOrderStatus {
    return this.orderStatusDefinitions.find((status: IOrderStatus) => {
      return status.orderStatusId === orderStatusId;
    });
  }
  //
  public get orderItemStatusDefinitions(): IOrderItemStatus[] {
    const statuses = [
      {
        orderItemStatusId: 1,
        name: 'Ready',
        description: 'Item has not been picked yet.'
      },
      {
        orderItemStatusId: 2,
        name: 'Picked',
        description: 'Item has been picked.'
      },
      {
        orderItemStatusId: 3,
        name: 'Subbed',
        description: 'Item has been picked.'
      },
      {
        orderItemStatusId: 4,
        name: 'Shorted',
        description: 'Item has been shorted.'
      },
      {
        orderItemStatusId: 5,
        name: 'Prep Complete',
        description: 'Prepped Item has been preppared, but not picked.'
      },
      {
        orderItemStatusId: 6,
        name: 'Prep Pick Complete',
        description: 'Prepped Item has been fully preppared and picked.'
      },
      {
        orderItemStatusId: 7,
        name: 'Collected',
        description: 'Item has been collected.'
      },
    ];
    return statuses;
  }
  public orderItemStatusDefinition(orderItemStatusId: number): IOrderItemStatus {
    return this.orderItemStatusDefinitions.find((status: IOrderItemStatus) => {
      return status.orderItemStatusId === orderItemStatusId;
    });
  }
  //
  public get orderTypeDefinitions(): IOrderType[] {
    const types = [
      {
        orderTypeId: 1,
        name: 'Carryout',
        description: 'Customer will be picking the order up from the store in-person.'
      },
      {
        orderTypeId: 2,
        name: 'Delivery',
        description: 'Delivery to customer\'s address is required.'
      }
    ];
    return types;
  }
  public orderTypeDefinition(orderTypeId: number): IOrderType {
    return this.orderTypeDefinitions.find((status: IOrderType) => {
      return status.orderTypeId === orderTypeId;
    });
  }
  //
  public get orderItemTypeDefinitions(): IOrderItemType[] {
    const types = [
      {
        orderItemTypeId: 1,
        name: 'Product',
      },
      {
        orderItemTypeId: 2,
        name: 'ShoppingFee',
      },
      {
        orderItemTypeId: 3,
        name: 'DeliveryFee',
      },
      {
        orderItemTypeId: 4,
        name: 'Subscription',
      },
      {
        orderItemTypeId: 5,
        name: 'Offer',
      },
      {
        orderItemTypeId: 6,
        name: 'Tip',
      },
    ];
    return types;
  }
  public orderItemTypeDefinition(orderItemTypeId: number): IOrderItemType {
    return this.orderItemTypeDefinitions.find((type: IOrderItemType) => {
      return type.orderItemTypeId === orderItemTypeId;
    });
  }
  //
  public getFilterDates(maxFutureDates?: number, maxHistoricDatesIncludingToday?: number): IHistoricFilterDate[] {
    const dates: IHistoricFilterDate[] = [];

    const numberOfFutureDays = maxFutureDates || 0;
    for (let i = 1; i <= maxFutureDates; i++) { // notice the <, and not <=
      // console.log('date tick');
      const appendedDate: IHistoricFilterDate = {
        label: '',
        startDate: this.getFilteredStartDateFromDayOffset(i),
        dayOffset: i,
        dateCode: ''
      };
      appendedDate.label = appendedDate.startDate.format('ddd, M-DD'); // day of week + short month/date
      appendedDate.dateCode = appendedDate.startDate.format('YYYY-MM-DD');
      dates.push(appendedDate);
    }

    const numberOfHistoricDays = maxHistoricDatesIncludingToday || 14;
    for (let i = 0; i < numberOfHistoricDays; i++) { // notice the <, and not <=
      const appendedDate: IHistoricFilterDate = {
        label: '',
        startDate: this.getFilteredStartDateFromDayOffset(-i),
        dayOffset: -i,
        dateCode: ''
      };
      appendedDate.label = appendedDate.startDate.format('ddd, M-DD'); // day of week + short month/date
      appendedDate.dateCode = appendedDate.startDate.format('YYYY-MM-DD');  // M-DD-YYYY - Mozilla has trouble (And I think Apple)
      dates.push(appendedDate);
    }

    const sorted = dates.sort((d1, d2) => {
      const a = d1.startDate.toDate().valueOf();
      const b = d2.startDate.toDate().valueOf();
      return b - a;
    });
    // console.log(sorted);
    return sorted;
  }
  private getFilteredStartDateFromDayOffset(offset: number, startDate?: moment.Moment) {
    const date = moment();
    date.add(offset || 0, 'days');
    return date;
  }
  //
  public get eventTypeDefinitions(): IEventType[] {
    const types = [
      {
        eventTypeId: 1,
        label: 'New Order',
      },
      {
        eventTypeId: 2,
        label: 'Lead Time Alert',
      },
      {
        eventTypeId: 3,
        label: 'Carryout Taken',
      },
      {
        eventTypeId: 4,
        label: 'Carryout Here',
      },
      {
        eventTypeId: 5,
        label: 'Carryout OMW',
      },
      {
        eventTypeId: 6,
        label: 'Delivery Here',
      },
      {
        eventTypeId: 7,
        label: 'Delivery Status',
      },
      {
        eventTypeId: 8,
        label: 'Delivery Error',
      },
    ];
    return types;
  }
  public eventTypeDefinition(eventTypeId: number): IEventType {
    return this.eventTypeDefinitions.find((status: IEventType) => {
      return status.eventTypeId === eventTypeId;
    });
  }
  //
  public get eventOptionMaxEventCountDefinitions(): IOptionNumber[] {
    const types = [
      {
        optionId: 1,
        value: 10,
      },
      {
        optionId: 2,
        value: 50,
      },
      {
        optionId: 3,
        value: 100,
      },
      {
        optionId: 4, // default (below)
        value: 500,
      },
      {
        optionId: 5,
        value: 1000,
      },
      // {
      //    optionId: 6,
      //    value: 3000,
      // }
    ];
    return types;
  }
  public eventOptionMaxEventCountDefinition(id?: number): IOptionNumber {
    const optionId = id || 4;
    return this.eventOptionMaxEventCountDefinitions.find((opt: IOptionNumber) => {
      return opt.optionId === optionId;
    });
  }
  //
  public get eventOptionGroupEventsDefinitions(): IOptionString[] {
    const types = [
      {
        optionId: 1,
        name: 'Default', // default (below)
      },
      // {
      //    optionId: 2,
      //    name: 'Grouped'
      // }
    ];
    return types;
  }
  public eventOptionGroupEventsDefinition(id?: number): IOptionString {
    const optionId = id || 1;
    return this.eventOptionGroupEventsDefinitions.find((opt: IOptionString) => {
      return opt.optionId === optionId;
    });
  }
}

