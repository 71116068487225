import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';

import { TranslationService } from 'src/app/@core/services/translation-service';
import { CustomDialogAction } from '../../models/custom-dialog';
import { CustomDialogService } from '../../services/custom-dialog-service';

@Component({
  selector: 'sk-custom-modal-dialog',
  templateUrl: './custom-modal-dialog.component.html',
  styleUrls: ['./custom-modal-dialog.component.scss']
})
export class CustomModalDialogComponent implements OnInit, OnDestroy  {
  @ViewChild('modal', { read: TemplateRef }) modal: TemplateRef<any>;
  title = '';
  cancelLabel = '';
  selectLabel = '';
  template: TemplateRef<any> = null;
  disabled = false;
  sizeLg = false;
  centered = false;
  hideCancelBtn = false;
  showHeader = true;
  showFooter = true;
  noPadding = false;
  container = null;
  bgTransparent = false;
  private responseData = null;
  private customDialogSubscription: Subscription;
  constructor(
    private modalService: NgbModal,
    private translationService: TranslationService,
    private customDialogService: CustomDialogService
  ) { }

  ngOnInit() {
    this.cancelLabel = this.translationService.translate('Cancel');
    this.selectLabel = this.translationService.translate('SELECT');
    this.customDialogSubscription = this.customDialogService.dialogSubject$.subscribe(action => {
      if (action.actionType === CustomDialogAction.Show) {
        this.title = action.title;
        this.template = action.bodyTemplate;
        this.cancelLabel = action.cancelLabel || this.translationService.translate('Cancel');
        this.selectLabel = action.selectlabel || this.translationService.translate('SELECT');
        this.disabled = action.selectBtnDisabled || false;
        this.sizeLg = action.sizeLg || false;
        this.centered = action.centered || false;
        this.responseData = action.contextData;
        this.hideCancelBtn = action.hideCancelBtn || false;
        this.showHeader = action.showHeader !== null && action.showHeader !== undefined ? action.showHeader : true;
        this.showFooter = action.showFooter !== null && action.showFooter !== undefined ? action.showFooter : true;
        this.noPadding = action.noBodyPadding  || false;
        this.container = action.parentContainer;
        this.bgTransparent = action.bgTransparent || false;
        this.modalService.open(this.modal, {
          size: action.sizeLg ? 'lg' : 'sm',
          container: this.container}).result
        .then((result) => {
          console.log('Result Open:', result);
        }, (reason) => {
          const reasonCause = this.getDismissReason(reason);
          console.log(`Dismissed: ${reasonCause}`);
          if (reasonCause === 'ESC') {
            this.cancel();
          }
        });
      } else if (action.actionType === CustomDialogAction.SelectBtnDisabled) {
        this.disabled = action.selectBtnDisabled || false;
      } else if (action.actionType === CustomDialogAction.Hide) {
        this.cancel(this.responseData !== null);
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clearAll() {
    this.title = '';
    this.template = null;
    this.disabled = false;
    this.sizeLg = false;
    this.centered = false;
    this.showHeader = true;
    this.showFooter = true;
    this.container = null;
    this.cancelLabel = this.translationService.translate('CANCEL');
    this.selectLabel = this.translationService.translate('SELECT');
  }

  ngOnDestroy(): void {
    this.customDialogSubscription.unsubscribe();
  }

  getTranslation(term: string): string {
    return this.translationService.translate(term);
  }

  cancel(propagate: boolean = true) {
    this.clearAll();
    this.modalService.dismissAll('cancel');
    if (propagate) {
      this.customDialogService.dialogActionSubject$.next({
        type: CustomDialogAction.Cancel,
        data: this.responseData
      });
    }
  }

  select() {
    if (!this.disabled) {
      this.clearAll();
      this.modalService.dismissAll('select');
      this.customDialogService.dialogActionSubject$.next({
        type: CustomDialogAction.Select,
        data: this.responseData
      });
    }
  }
}
