
<div *ngIf="idleListener.isShowing" class="idle-listener-modal">
    <div class="idle-listener-wrapper">
        <div class="idle-listener-content widget-wrapper"
            [@idleListenerAnimatedModal]="idleListener.idleAnimationState">
            <div class="idle-listener-heading">
                Whoops!
            </div>
            <div class="idle-listener-body">
                Looks like you've gone inactive ...
                <div class="idle-listener-button-row">
                    <button class="btn btn-primary p-2 m-2"
                            (click)="idleListener.startReset()">
                        I'm Still Working!
                    </button>
                    <button class="btn btn-danger p-2 m-2"
                            (click)="idleListener.logoff()">
                        Log Out
                    </button>
                </div>
                Automatically logging off in {{ idleListener.timeBeforeLogoff }} seconds.
            </div>
        </div>
    </div>
</div>       
