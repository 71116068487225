﻿import { Injectable } from '@angular/core';
import * as XLSX from "xlsx";

import { LoggerService } from './logger-service';

export class XlsxWorkbook {
    worksheets: XlsxWorksheet[] = [];
    filename: string;
    addWorksheet(ws: XlsxWorksheet) {
        this.worksheets.push(ws);
    }
}

export class XlsxWorksheet {
    name: string;
    data: any[];
}

@Injectable()
export class XlsxService {

    constructor(
        private loggerService: LoggerService,
    ) {
    }

    // NEW
    exportAsXlsx(wb: XlsxWorkbook) {
        this.loggerService.logData({
            message: 'XlsxService - exporting workbook',
            workbook: wb
        });

        let _wb: XLSX.WorkBook = XLSX.utils.book_new();

        wb.worksheets.forEach((ws, index) => {
            const _ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ws.data);
            const name = ws.name || `data-${index}`;
            XLSX.utils.book_append_sheet(_wb, _ws, name );
        });

        XLSX.writeFile(_wb, wb.filename);
    }

    // OLD
    exportToWorkbookWithSingleWorksheet(data: any[], fileName: string, worksheetName?: string) {
        const wsName = worksheetName || 'data';
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        //const wb: XLSX.WorkBook = { Sheets: { wsName: ws }, SheetNames: [wsName] };
        let wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, wsName);
        XLSX.writeFile(wb, fileName);
    }
}