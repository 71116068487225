﻿
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import 'style-loader!@swimlane/ngx-charts/release/common/base-chart.component.css';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  SkBubbleChartComponent,
  SkBubbleChartSeriesComponent,
  SkBubbleChartSeriesCircleComponent
} from './charts/sk-bubble-chart';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafePipe, MomentPipe, FormatDatePipe } from './pipes';
import { NgPipesModule } from 'ngx-pipes';
import { SkAnimatedFlipCardComponent } from './components/animatedFlipCard';
import { SkAnimatedCartSpinnerComponent } from './components/animatedCartSpinner/animatedCartSpinner';
import { FileUploadComponent } from './components/fileupload/fileupload';
import { TimePickerComponent } from './components/timepicker/timepicker';
import { DatePickerComponent } from './components/datetime/date-picker';
import { SplitBarComponent } from './components/splitBar/splitBar';
import { InspectorColumnFilterSortComponent } from './components/inspectorColumnFilterSort';
import { OrderModalComponent } from './components/order-modal/order-modal.component';
import { ReserveModalComponent } from './components/reserve-modal/reserve-modal.component';
import { OrderStatusHistoryModalComponent } from './components/order-status-history-modal/order-status-history-modal.component';
import { DeliveryStatusHistoryModalComponent } from './components/delivery-status-history-modal/delivery-status-history-modal.component';;
import { TimeSelectorComponent } from './components/time-selector/time-selector.component';
import { InputBoxComponent } from './components/inputbox/inputbox.component';
import { OrderService } from '../@core/services/order-service';
import { CapitalizePipe } from './pipes/capitalize';
import { ProgressBarModalComponent } from './components/progressbar-modal/progressbar-modal.component';

@NgModule({
  imports: [
    // ng
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // ngx charts
    NgxChartsModule,
    AngularMultiSelectModule,
    NgPipesModule,
    NgxPaginationModule,
    NgbModule
  ],
  declarations: [
    SafePipe,
    MomentPipe,
    FormatDatePipe,
    CapitalizePipe,
    SkAnimatedCartSpinnerComponent,
    SkAnimatedFlipCardComponent,
    FileUploadComponent,
    TimePickerComponent,
    DatePickerComponent,
    SplitBarComponent,
    InspectorColumnFilterSortComponent,
    // ngx charts
    SkBubbleChartComponent, SkBubbleChartSeriesComponent, SkBubbleChartSeriesCircleComponent,
    ReserveModalComponent,
    OrderModalComponent,
    OrderStatusHistoryModalComponent,
    DeliveryStatusHistoryModalComponent,
    TimeSelectorComponent,
    InputBoxComponent,
    ProgressBarModalComponent
  ],
  providers: [OrderService],
  exports: [
    // ng
    FormsModule,
    ReactiveFormsModule,
    // pipes
    SafePipe,
    MomentPipe,
    FormatDatePipe,
    NgPipesModule,
    NgbModule,
    CapitalizePipe,
    // components
    SkAnimatedCartSpinnerComponent,
    SkAnimatedFlipCardComponent,
    FileUploadComponent,
    TimePickerComponent,
    DatePickerComponent,
    SplitBarComponent,
    InspectorColumnFilterSortComponent,
    // ngx charts
    NgxChartsModule,
    SkBubbleChartComponent, SkBubbleChartSeriesComponent, SkBubbleChartSeriesCircleComponent,
    AngularMultiSelectModule,
    NgxPaginationModule,
    ReserveModalComponent,
    OrderModalComponent,
    OrderStatusHistoryModalComponent,
    DeliveryStatusHistoryModalComponent,
    TimeSelectorComponent,
    InputBoxComponent,
    ProgressBarModalComponent
  ],
  entryComponents: [
    OrderModalComponent,
    ReserveModalComponent,
    OrderStatusHistoryModalComponent,
    DeliveryStatusHistoryModalComponent,
    ProgressBarModalComponent
  ]
})
export class AppSharedModule { }

